import React from "react"
import BrowserProtocol from "farce/BrowserProtocol"
import queryMiddleware from "farce/queryMiddleware"
import { Resolver } from "found-relay"
import createFarceRouter from "found/createFarceRouter"

import "./App.scss"

import getEnvironment from "api/graphql"
import routes from "routes"

import { BourseContext } from "./App.Context"

const App = () => {
  const body = document.getElementsByTagName("body")?.[0]
  const [contextValue, setContextValue] = React.useState({
    bourseId: body?.id,
    environment: getEnvironment(),
    refreshEnv: () =>
      //refresh the environment and recreate a new one
      setContextValue((v) => ({ ...v, environment: getEnvironment() })),
  })

  const resolver = new Resolver(contextValue.environment)
  const Router = createFarceRouter({
    historyProtocol: new BrowserProtocol(),
    historyMiddlewares: [queryMiddleware],
    routeConfig: routes,
  })
  return (
    <React.Suspense fallback={<div />}>
      <BourseContext.Provider value={contextValue}>
        <Router resolver={resolver} />
      </BourseContext.Provider>
    </React.Suspense>
  )
}

export default App
