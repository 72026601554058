/**** File generated by runnning yarn permissions ****/
  /* tslint:disable */
  /* eslint-disable */
  // @ts-nocheck
  
  const fr = {
  "Account": {
    "password_title": "Modifier le mot de passe",
    "old_password": "Ancien mot de passe",
    "hide": "Cacher",
    "show": "Afficher",
    "password": "Mot de passe",
    "save": "Enregistrer",
    "edited_password_ok": "Mot de passe modifié",
    "profile_title": "Modifier le profil",
    "image": "Image",
    "first_name": "Prénom",
    "last_name": "Nom",
    "email": "Email",
    "phone": "Téléphone",
    "matricule": "Matricule",
    "niu": "Niu",
    "language": "Langue",
    "edited_profile_ok": "Profil modifié"
  },
  "AdminAgents": {
    "page_title": "Comptes",
    "add": "Ajouter"
  },
  "AdminAnnounce": {
    "page_title": "Annonce",
    "back": "Liste des annonces"
  },
  "AdminAnnounceHistory": {
    "created": "a créé l'annonce et l'a soumise pour validation",
    "rejected": "a rejété l'annonce.",
    "activated": "a activé l'annonce",
    "start_transfer": "a initié le le transfer",
    "from": "Départure: {{date}}",
    "to": "Arrivée: {{date}}",
    "added_transit": "a ajouté un transit {{details}}",
    "ended_transfer": "a finalisé le transfer",
    "ended_process": "a terminé le traitement des déchets",
    "title": "Historique"
  },
  "AdminAnnouncePreviewModal": {
    "title": "Aperçu de l'announce"
  },
  "AdminAnnounces": {
    "page_title": "Annonces"
  },
  "AdminCategories": {
    "page_title": "Catégories",
    "add": "Ajouter"
  },
  "AdminCodes": {
    "page_title": "Codes",
    "add": "Ajouter"
  },
  "AdminDepartments": {
    "page_title": "Départements",
    "add": "Ajouter"
  },
  "AdminDistricts": {
    "page_title": "Arrondissements",
    "add": "Ajouter"
  },
  "AdminInterests": {
    "page_title": "Intérêts"
  },
  "AdminManifests": {
    "page_title": "Manifestes"
  },
  "AdminRegions": {
    "page_title": "Régions",
    "add": "Ajouter"
  },
  "AdminReport": {
    "quantity": "Quantité (Kg/L)",
    "appearance": "Nombre d'annonces"
  },
  "AdminReports": {
    "page_title": "Rapports",
    "description": ""
  },
  "AdminRoles": {
    "page_title": "Rôles",
    "add": "Ajouter"
  },
  "AdminSocieties": {
    "page_title": "Sociétés",
    "add": "Ajouter"
  },
  "AdminTypes": {
    "page_title": "Types",
    "add": "Ajouter"
  },
  "AgentAdd": {
    "invite_user": "Inviter l' utilisateur",
    "full_name": "Nom",
    "matricule": "Matricule",
    "phone": "Téléphone",
    "email": "Email",
    "roles": "Rôles",
    "ok": "Invitation envoyée"
  },
  "AgentAdminItem": {
    "mail_verified": "Addresse email vérifiée",
    "matricule": "Matricule",
    "joined_from": "a rejoint",
    "assign_new_role": "Affecter un nouveau rôle",
    "resend_invitation": "Renvoyer l'invitation",
    "archive": "Archiver",
    "unarchive": "Désarchiver",
    "delete": "Supprimer",
    "GENERAL": "GENERAL"
  },
  "AgentAdminList": {
    "user": "Utilisateur",
    "roles": "Rôles"
  },
  "AgentBtnArchive": {
    "ok": "Compte archivé.",
    "confirm_title": "Archiver le compte ?",
    "confirm_desc": "Une fois archivé, cet utilisateur ne pourra plus accéder à la plateforme via ce compte",
    "cancel": "Annuler",
    "archive": "Archiver"
  },
  "AgentBtnRemove": {
    "ok": "Compte supprimé",
    "confirm_title": "Supprimer le compte",
    "confirm_desc": "Cette opération est irreversible !! Dans la mesure du possible, optez pour l'archivage",
    "cancel": "Annuler",
    "delete": "Supprimer"
  },
  "AgentBtnResendInvitation": {
    "ok": "Invitation envoyée",
    "confirm_title": "Renvoyer l'invitation ?",
    "confirm_desc": "Un nouveau mail sera envoyer à l'utilisateur. Ce mail lui permettra de réinitialiser son mot de passe",
    "cancel": "Annuler",
    "resendInvitation": "Renvoyer"
  },
  "AgentBtnUnArchive": {
    "ok": "Compte désarchivé.",
    "confirm_title": "Désarchiver le compte ?",
    "confirm_desc": "L'utilisateur pourra de nouveau accéder à la plateforme.",
    "cancel": "Annuler",
    "unarchive": "Désarchiver"
  },
  "AnnounceAdminHistory": {
    "created": "a crée l'annonce et l'a soumise pour validation",
    "rejected": "a rejeté l'annonce",
    "activated": "a activé l'annonce",
    "start_transfer": "a initié le transfert",
    "from": "de {{date}}",
    "to": "à {{date}}",
    "added_transit": "a ajouté un transit: {{details}}",
    "ended_transfer": "a finalisé le transfert: {{date}}",
    "ended_process": "a terminé le processus: {{date}}",
    "title": "Historique"
  },
  "AnnounceAdminItem": {
    "interest_list": "{{nInterests}} interêt(s)",
    "added_by": "Ajouté par",
    "details": "Détails",
    "place": "Emplacement",
    "destination": "Destination",
    "show_preview": "Afficher l'aperçu",
    "view_history": "Afficher l'historique",
    "view_interest_list": "Voir la liste des interêts ({{nInterests}})",
    "activate": "Activer",
    "reject": "Rejeter",
    "edit_manifest": "Modifier le manifeste",
    "init_transfert": "Initier le transfert",
    "add_transit": "Ajouter le prochain transit",
    "mark_transfered": "Marqué comme transféré",
    "terminate": "Terminer le traitement",
    "archive": "Archiver",
    "unarchive": "Désarchiver"
  },
  "AnnounceBoardComposer": {
    "title": "Proposez vos déchets",
    "description": "Et réalisez des gains substantiels en assainissant notre environnement",
    "create_announce": "Créer mon announce"
  },
  "AnnounceBoardItem": {
    "interest_list": "{{nInterests}} interêt(s)",
    "contact_owner": "Contacter le propriétaire",
    "view_interest_list": "Voir la liste des interêts ({{nInterests}})",
    "share": "Partager",
    "edit": "Modifier",
    "resubmit": "Resoumettre"
  },
  "AnnounceBtnActivate": {
    "ok": "Annonce activée.",
    "confirm_title": "Activer l'annonce ?",
    "confirm_desc": "Une fois l'annonce activée, elle sera visible publiquement par tout visiteur.",
    "cancel": "Annuler",
    "activate": "Activer"
  },
  "AnnounceBtnArchive": {
    "ok": "Annonce archivée.",
    "confirm_title": "Archiver l'annonce ?",
    "confirm_desc": "Une fois archivée, cette annonce n'apparaîtra plus dans la liste des annonces. Mais ses données seront toujours disponibles pour les statistiques. Le manifeste, les transits et les intérêts liés à cette announce seront aussi archivés.",
    "cancel": "Annuler",
    "archive": "Archiver"
  },
  "AnnounceBtnEndProcess": {
    "ok": "Processus de traitement terminé",
    "date_req": "Préciser la date de la fin du traitement.",
    "confirm_title": "Marquer les déchets comme traités?",
    "confirm_desc": "C'est la dernière étape du processus !!!. Valider ceci pour confirmer que les déchets ont été traités comme prévu. Préciser également la date de la fin du traitement.",
    "cancel": "Annuler",
    "end_process": "Oui",
    "date": "Date de la fin du traitement."
  },
  "AnnounceBtnReject": {
    "ok": "Annonce rejetée.",
    "confirm_title": "Rejeter l'annonce ?",
    "confirm_desc": "Lorsque vous rejetez une annonce, un e-mail avec le motif sera envoyé à l'auteur.",
    "cancel": "Annuler",
    "reject": "Rejeter",
    "reject_reason": "motif du rejet"
  },
  "AnnounceBtnResubmit": {
    "ok": "Demande envoyée.",
    "confirm_title": "Resoumettre l'annonce pour validation ?",
    "confirm_desc": "Vérifiez que tout est correct. Une fois renvoyé, vous ne pourrez plus modifier l'annonce à moins qu'elle ne soit rejetée à nouveau",
    "cancel": "Annuler",
    "resubmit": "Resoumettre"
  },
  "AnnounceBtnUnArchive": {
    "ok": "Annonce désarchivée.",
    "confirm_title": "Désarchiver l'annonce ?",
    "confirm_desc": "Le manifeste, les transits et les intérêts liés à cette announce seront aussi désarchivés.",
    "cancel": "Annuler",
    "unarchive": "Désarchiver"
  },
  "AnnounceDetail": {
    "share": "Partager",
    "contact_owner": "Contacter le propriétaire",
    "edit": "Modifier",
    "resubmit": "Resoumettre",
    "place": "Emplacement",
    "interest_list": "interêts ({{nInterests}})"
  },
  "AnnounceInterestListModal": {
    "title": "Intérêts"
  },
  "AnnounceSave": {
    "edit_title": "Modifier l'annonce",
    "create_title": "Créer une annonce",
    "images": "Images",
    "name": "Nom",
    "description": "Description",
    "qty": "Quantité (Kg/L)",
    "localisation": "Emplacement",
    "city": "Ville",
    "address": "Addresse",
    "lattitude": "Lattitude",
    "longitude": "Longitude",
    "edited_ok": "Annonce modifiée",
    "created_ok": "Annonce soumise à vérification"
  },
  "AnnounceSaveEndTransfer": {
    "mark_as_transfered": "Marquer comme Transféré et débuter le traitement",
    "final_destination": "Destination finale",
    "arrival_date": "Date d'arrivée",
    "conformity": "Observations sur la quantité et la qualité des déchets reçus",
    "conformity_ok": "Conforme",
    "conformity_not_ok": "Non conforme",
    "observations": "Motif de non conformité",
    "ok": "Déchets Transferés et traitement en cours",
    "conformity_obs_req": "Préciser le motif de non conformité"
  },
  "AnnounceStateSelect": {
    "select": "Sélectionner l'état"
  },
  "Auth": {
    "reset_pwd": "Réinitialiser le mot de passe",
    "reset_pwd_description": "Entrez votre nouveau de mot de passe pour compléter le processus.",
    "reset_pwd_error": "Une erreur est survenue. Le lien de vérification est invalide, veuillez réinitailiser de nouveau votre mot de passe.",
    "pwd": "Mot de passe",
    "hide": "Cacher",
    "show": "Afficher",
    "signin": "Connexion",
    "reset": "Réinitialiser",
    "send_reset_pwd": "Réinitialiser le mot de passe",
    "send_reset_pwd_description": "Entrez votre addresse email. Vous recevrez ensuite un mail avec les instructions à suivre.",
    "send_reset_pwd_ok": "Le mail de réinitialisation a bien été envoyé. Vérifiez votre boîte mail et les spams si nécessaire",
    "send_reset_pwd_error": "Une erreur est survenue. Vérifiez votre addresse email et réessayez.",
    "email": "Email",
    "send_mail": "Envoyer le mail",
    "or": "ou",
    "signup": "Inscription",
    "signin_error": "Une erreur est survenue. Vérifiez vos paramètres de connexion et réessayez",
    "identity_desc": "Email, téléphone ou matricule",
    "identity": "Identifiant",
    "keep_me_in": "Rester connecté",
    "forget_pwd": "Mot de passe oublié",
    "error": "Une erreur est survenue. Vérifiez vos paramètres de connexion et réessayez.",
    "full_name": "Nom complet",
    "phone": "Téléphone",
    "niu": "Numéro d'identifiant unique",
    "verify_set_pwd": "Initialition du mot de passe",
    "verify_set_pwd_description": "Saisissez votre nouveau mot de passe pour accéder à la plateforme.",
    "verify_set_pwd_error": "Essayez de nouveau. Si l'erreur persiste, demandez à l'administrateur de vous renvoyer un nouveau mail d'invitation."
  },
  "AuthCheck": {
    "sign_up": "Inscrivez vous",
    "close": "Fermer",
    "signup_desc": "Inscrivez vous pour acceder à cette page",
    "already_in": "Vous avez un compte ?",
    "sign_in": "Connectez vous"
  },
  "AuthSignin": {
    "signin": "",
    "or": "",
    "signup": "",
    "error": "",
    "identity_desc": "",
    "identity": "",
    "hide": "",
    "show": "",
    "keep_me_in": ""
  },
  "BoardAnnounce": {
    "page_title": "Annonce",
    "back": "Accueil"
  },
  "BoardAnnounces": {
    "page_title": "Annonces"
  },
  "CategoryBtnRemove": {
    "ok": "Catégorie supprimée",
    "confirm_title": "Supprimer la catégorie",
    "confirm_desc": "Cette opération est irreversible !!",
    "cancel": "Annuler",
    "delete": "Supprimer"
  },
  "CategoryItem": {
    "edit": "Modifier",
    "remove": "Supprimer"
  },
  "CategoryList": {
    "name": "Nom",
    "code": "Code",
    "description": "Description"
  },
  "CategorySave": {
    "edit_title": "Modifier la catégorie",
    "create_title": "Ajouter une catégorie",
    "name": "Nom",
    "code": "Code",
    "description": "Description",
    "edited_error": "",
    "edited_ok": "Catégorie modifiée",
    "created_error": "",
    "created_ok": "Nouvelle catégorie ajouté"
  },
  "CheckAuth": {
    "sign_up": "Inscription",
    "signup_desc": "Inscrivez vous pour acceder à cette page",
    "already_in": "avez vous déjà un  compte ?",
    "sign_in": "Connectez vous",
    "close": "Fermer"
  },
  "CodeBtnRemove": {
    "ok": "Code supprimé",
    "confirm_title": "Supprimer le code",
    "confirm_desc": "Cette opération est irreversible !!",
    "cancel": "Annuler",
    "delete": "Supprimer"
  },
  "CodeItem": {
    "edit": "Modifier",
    "remove": "Supprimer"
  },
  "CodeList": {
    "code": "Code",
    "description": "Description"
  },
  "CodeSave": {
    "edit_title": "Modifier le code",
    "create_title": "Ajouter un code",
    "code": "Code",
    "description": "Description",
    "edited_error": "",
    "edited_ok": "Code modifié",
    "created_error": "",
    "created_ok": "Nouveau code ajouté"
  },
  "DateRangePicker": {
    "today": "Aujourd'hui",
    "yesterday": "Hier",
    "last_30": "30 derniers jours",
    "last_7": "7 derniers jours",
    "this_week": "Cette semaine",
    "this_month": "Ce mois",
    "last_month": "Le mois dernier",
    "custom_range": "Personnaliser",
    "from": "De",
    "to": "A",
    "apply": "Appliquer",
    "cancel": "Annuler"
  },
  "DepartmentBtnRemove": {
    "ok": "Département supprimé",
    "confirm_title": "Supprimer le département",
    "confirm_desc": "Cette opération est irreversible !!",
    "cancel": "Annuler",
    "delete": "Supprimer"
  },
  "DepartmentItem": {
    "edit": "Modifier",
    "remove": "Supprimer"
  },
  "DepartmentList": {
    "name": "Non",
    "capital": "Chef-lieu",
    "region": "Région"
  },
  "DepartmentSave": {
    "edit_title": "Modifier le département",
    "create_title": "Ajouter un département",
    "name": "Nom",
    "capital": "Chef-lieu",
    "region": "Région",
    "edited_ok": "Département modifié",
    "created_ok": "Nouveau département ajouté"
  },
  "DistrictBtnRemove": {
    "ok": "Arrondissement supprimé",
    "confirm_title": "Supprimer l'arrondissement",
    "confirm_desc": "Cette opération est irreversible !!",
    "cancel": "Annuler",
    "delete": "Supprimer"
  },
  "DistrictItem": {
    "edit": "Modifier",
    "remove": "Supprimer"
  },
  "DistrictList": {
    "name": "Nom",
    "region-division": "Région-département"
  },
  "DistrictSave": {
    "edit_title": "Modifier l'arrondissement",
    "create_title": "Ajouter un arrondissement",
    "name": "Nom",
    "departement": "Département",
    "region": "Région",
    "edited_ok": "Arrondissement modifié",
    "created_ok": "Nouveau arrondissement ajouté"
  },
  "Enums": {
    "weight": "Kg",
    "volume": "L",
    "collection": "collecte",
    "storage": "stockage",
    "transportation": "transport",
    "recycling": "recyclage",
    "disposal": "élimination",
    "sorting": "tri",
    "manufacturing": "fabrication",
    "distribution": "distribution",
    "recovery": "récupération",
    "marketing": "marketing",
    "transport": "transport",
    "importation": "importation",
    "treatment": "traitement",
    "explosive": "explosif",
    "flammable": "facilement inflammable",
    "oxidizer": "comburant",
    "irritant": "irritant",
    "harmful": "nocif",
    "toxic": "toxique",
    "cancerogenic": "cancérigène",
    "corrosive": "corrosif",
    "infectious": "infectieux",
    "radioactive": "radioactif",
    "h11": "h11",
    "h12": "h12",
    "h13": "h13",
    "solid": "solide",
    "liquid": "liquide",
    "mud": "boue",
    "phys_other": "autre",
    "tbucket": "benne",
    "cast": "fut",
    "cistern": "citerne",
    "cond_other": "autre",
    "road": "route",
    "train": "train",
    "sea": "mer",
    "river": "fleuve",
    "air": "air",
    "valorisation": "valorisation",
    "landfill": "mise en décharge",
    "stabilization": "stabilisation",
    "pctreatment": "traitement physico-chimique",
    "incineration": "incinération",
    "instr_other": "autre",
    "waiting": "en attente",
    "rejected": "rejeté",
    "validated": "validé",
    "moving": "en déplacement",
    "processing": "traitement en cours",
    "processed": "traitement terminé",
    "settings-access": "Lister les paramètres",
    "settings-edit": "Gérer les paramètres",
    "user-list": "Lister les utilisateurs",
    "user-edit": "Gérer les utilisateurs",
    "role-edit": "Gérer les rôles",
    "report-list": "Afficher les rapports",
    "announce-list": "Lister les announces",
    "announce-edit": "Modifier les announces",
    "announce-activate": "Activer les announces",
    "announce-reject": "Rejeter les announces",
    "announce-start-transfer": "Initier les transferts",
    "announce-add-transit": "Ajouter les transits",
    "announce-end-transfer": "Terminer les transferts et initier les traitements",
    "announce-end-process": "Finaliser les traitements",
    "announce-archive": "Archiver et Désarchiver les announces"
  },
  "FilePicker": {
    "labelIdle": "Glissez & déposez vos fichiers ou <span class=\"filepond--label-action\"> Cliquer ici </span>",
    "labelInvalidField": "Le champ contient des fichiers non valides",
    "labelFileWaitingForSize": "En attente de la taille",
    "labelFileSizeNotAvailable": "Taille non disponible",
    "labelFileCountSingular": "fichier dans la liste",
    "labelFileCountPlural": "fichiers dans la liste",
    "labelFileLoading": "Chargement",
    "labelFileAdded": "Ajouté",
    "labelFileLoadError": "Erreur lors du chargement",
    "labelFileRemoved": "Supprimé",
    "labelFileRemoveError": "Erreur lors de la suppression",
    "labelFileProcessing": "Téléchargement",
    "labelFileProcessingComplete": "Téléchargement complet",
    "labelFileProcessingAborted": "Téléchargement annulTé",
    "labelFileProcessingError": "Erreur lors du téléchargement",
    "labelFileProcessingRevertError": "Erreur lors du retour",
    "labelTapToCancel": "appuyez pour annuler",
    "labelTapToRetry": "cliquer pour réessayer",
    "labelTapToUndo": "appuyez pour annuler",
    "labelButtonRemoveItem": "Retirer",
    "labelButtonAbortItemLoad": "Avorter",
    "labelButtonRetryItemLoad": "Retenter",
    "labelButtonAbortItemProcessing": "Annuler",
    "labelButtonUndoItemProcessing": "Annuler",
    "labelButtonRetryItemProcessing": "Retenter",
    "labelButtonProcessItem": "Télécharger",
    "labelMaxFileSizeExceeded": "Le fichier est trop volumineux",
    "labelMaxFileSize": "La taille maximale du fichier est de {filesize}",
    "labelMaxTotalFileSizeExceeded": "Taille totale maximale dépassée",
    "labelMaxTotalFileSize": "La taille totale maximale du fichier est de {filesize}",
    "labelFileTypeNotAllowed": "Fichier de type non valide",
    "fileValidateTypeLabelExpectedTypes": "Attend {allButLastType} ou {lastType}"
  },
  "FilterGeneric": {
    "select_archives": "Sélectionner les archives",
    "yes": "Oui",
    "search_by": "Recherche",
    "clear": "Effacer",
    "filter": "Filtrer",
    "click_to_edit_filters": "Cliquer pour filtrer",
    "select_transport_society": "Sélectionner la société",
    "transport_society": "Société de transport",
    "pick_category": "Sélectionner une catégorie",
    "all_category": "Toutes les catégories",
    "pick_department": "Sélectionner un département",
    "all_department": "Tous les départements",
    "pick_district": "Sélectionner un arrondissement",
    "all_district": "Tous les arrondissements",
    "filter_results": "Filtrer les résultats",
    "pick_region": "Sélectionner une région",
    "all_region": "Toutes les régions",
    "pick_state": "Sélectionner l'état",
    "all_state": "Tous les états",
    "pick_type": "Sélectionner un type",
    "all_type": "Tous les types"
  },
  "FilterGeneric_old": {
    "no": "Non"
  },
  "Footer": {
    "terms": "Conditions d'utlisation",
    "privacy": "Cadre légal",
    "communal_plan": "Plan communal de gestion des déchets",
    "text_law_convention": "Textes, lois et conventions",
    "contact_us": "Contactez nous"
  },
  "Global": {
    "eM_retry": "Réessayer",
    "no_items": "Aucun élément trouvé",
    "eM_req": "Champ obligatoire",
    "cancel": "Annuler",
    "save": "Enregistrer",
    "close": "Fermer",
    "language": "Langue",
    "send": "Envoyer",
    "error_msg": "Une erreur est survenue, vérifiez votre connexion et reéssayez",
    "eM_inv_period": "La période choisie est invalide"
  },
  "GroupByTimePeriod": {
    "hour": "Heure",
    "day": "Jour",
    "week": "Semaine",
    "month": "Mois",
    "year": "Année",
    "hour_of_day": "Heure de la journée",
    "day_of_week": "Jour de la semaine",
    "over": "Regrouper par"
  },
  "HeaderBoard": {
    "announces": "Annonces",
    "admin": "Administration",
    "manifests": "Manifestes",
    "interests": "Intérêts",
    "stats": "Analyse des données",
    "reports": "Rapports",
    "users": "Utilisateurs",
    "agents": "Comptes",
    "roles": "Rôles",
    "settings": "Paramètres",
    "societies": "Sociétés",
    "categories": "Catégories",
    "types": "Types",
    "waste_code": "Codes déchet",
    "regions": "Régions",
    "divisions": "Départments",
    "subdivisions": "Arrondissements",
    "useful_links": "Liens utiles",
    "terms": "Conditions d'utlisation",
    "privacy": "Cadre légal",
    "communal_plan": "Plan communal de gestion des déchets",
    "text_law_convention": "Textes, lois et conventions",
    "contact_us": "Contactez nous",
    "my_profile": "Mon profil",
    "my_announces": "Mes annonces",
    "my_interests": "Mes intérêts",
    "signout": "Déconnexion",
    "auth": "Authentification",
    "signin": "Connexion",
    "signup": "Inscription"
  },
  "ImagesCard": {
    "click_to_maximize": "Cliquez pour agrandir"
  },
  "InterestItem": {
    "address": "Addresse"
  },
  "InterestList": {
    "contact": "Contact",
    "message": "Message",
    "announce": "Announce"
  },
  "LoadMore": {
    "load_more": "Charger plus",
    "all_loaded": "Tous les éléments sont chargés",
    "showing": "{{edgeCount}} sur {{totalCount}} affiché(s)",
    "load_next": "Charger les {{nextCount}} prochains",
    "load_next_singular": "Charger le prochain",
    "previous": "Précédent",
    "next": "Suivant"
  },
  "ManifestAdminItem": {
    "departure": "Départ",
    "transits": "Transits",
    "vehicules": "Véhicules",
    "destination": "Destination",
    "arrival_date": "Arrivée",
    "edit": "Modifier",
    "niu": "NIU",
    "permi_no": "Permis"
  },
  "ManifestAdminList": {
    "details": "Détails",
    "transport": "Transport",
    "processing": "Traitement"
  },
  "ManifestSave": {
    "edited_error": "Erreur lors de la modification du manifeste",
    "edited_ok": "Manifeste modifié",
    "created_error": "Erreur lors de la génération du manifeste",
    "created_ok": "Manifeste généré",
    "edit_title": "Modifier le manifeste",
    "create_title": "Générer le manifeste de traçabilité",
    "gen_name": "Nom de l'émetteur",
    "gen_address": "Addresse de l'émetteur",
    "gen_phone": "Tel de l'émetteur",
    "prod_name": "Nom du producteur",
    "prod_address": "Addresse du producteur",
    "prod_phone": "Tel du producteur",
    "waste_description": "Description des déchets",
    "code": "Code",
    "description": "Description",
    "waste_caracteristics": "Caractéristiques des déchets",
    "remove_waste_description": "Retirer",
    "add_waste_description": "Ajouter",
    "phys_state": "Etat physique",
    "specify": "Préciser...",
    "conditionning": "Conditionnement",
    "number": "Nombre",
    "qty": "Quantité totale Kg/L",
    "instructions": "Description supplémentaire et/ou procédure de traitement",
    "trans_sct_name": "Compagnie de transport",
    "trans_sct": "Compagnie de transport",
    "trans_sct_address": "Addresse de la compagnie",
    "trans_sct_perm_no": "N<sup>o</sup> du permis Env.",
    "transport_mode": "Mode de transport",
    "cars": "Véhicules",
    "driver_name": "Nom du conducteur",
    "driver_cni": "N<sup>o</sup> CNI",
    "car_no": "N<sup>o</sup> du véhicule",
    "date": "Date",
    "remove_car": "Retirer",
    "add_car": "Ajouter",
    "des_sct_name": "Centre de traitement",
    "des_sct": "Centre de traitement",
    "des_sct_address": "Addresse du centre",
    "des_sct_perm_no": "N<sup>o</sup> du permis Env.",
    "departure_date": "Date de départ",
    "final_from": "Destination finale",
    "city": "Ville",
    "address": "Addresse (Quartier)",
    "observations": "Observations"
  },
  "Me": {
    "my_announces": "Mes annonces",
    "my_interests": "Mes intérêts",
    "my_profile": "Mon profil",
    "edit_pwd": "Modifer le mot de passe"
  },
  "MeAccount": {
    "page_title": "Mon compte"
  },
  "MeAnnounces": {
    "page_title": "Mes annonces"
  },
  "MeInterests": {
    "page_title": "Mes intérêts"
  },
  "OwnerContactModal": {
    "contact_owner": "Contacter le propriétaire",
    "contact_instructions": "Veuillez saisir vos informations personnelles et un message à envoyer au propriétaire",
    "name": "Nom",
    "email": "Email",
    "phone": "Téléphone",
    "city": "Ville",
    "address": "Addresse (Quartier)",
    "msg": "Message",
    "msg_description": "Entrez le message au propriétaire",
    "contact_owner_ok": "Votre demande a bien été envoyée au propriétaire. Il vous contactera si nécéssaire",
    "contact_owner_error": "Une erreur s'est produite, vérifiez votre connexion et réessayez"
  },
  "RegionBtnRemove": {
    "ok": "Région supprimée",
    "confirm_title": "Supprimer la région",
    "confirm_desc": "Cette opération est irreversible !!",
    "cancel": "Annuler",
    "delete": "Supprimer"
  },
  "RegionItem": {
    "edit": "Modifer",
    "remove": "Supprimer"
  },
  "RegionList": {
    "name": "Nom",
    "code": "Code",
    "capital": "Chef-lieu"
  },
  "RegionSave": {
    "edit_title": "Modifier la région",
    "create_title": "Ajouter une région",
    "name": "Nom",
    "code": "Code",
    "capital": "Chef-lieu",
    "edited_ok": "Région modifiée",
    "created_ok": "Nouvelle région ajoutée"
  },
  "Report": {
    "count": "{{count}} rapports. Cliquez sur \"Tout Afficher\" pour les afficher",
    "count_plural": "",
    "reports": "Rapports",
    "show_less": "Afficher moins",
    "show_all": "Tout afficher",
    "page_title": "Rapport",
    "description": "",
    "block_title_created": "Quantité de déchets ajoutés",
    "block_description_created": "Rapports sur les quantités de déchets(Kg/L) ajoutés et le nombre d'annonces créées",
    "block_title_rejected": "Quantité de déchets rejetés",
    "block_description_rejected": "Rapports sur les quantités de déchets(Kg/L) et le nombre d'annonces rejetés",
    "block_title_activated": "Quantité de déchets validés",
    "block_description_activated": "Rapports sur les quantités de déchets(Kg/L) et le nombre d'annonces validés",
    "block_title_transfering": "Quantité de déchets en cours de transfert",
    "block_description_transfering": "Rapports sur les quantités de déchets(Kg/L) le nombre d'annonces en cours de transfert",
    "block_title_transfered": "Quantité de déchets transférés",
    "block_description_transfered": "Rapports sur les quantités de déchets(Kg/L) et le nombre d'annonces transférés",
    "block_title_processing": "Quantité de déchets en cours de traitement",
    "block_description_processing": "Rapports sur les quantités de déchets(Kg/L) et le nombre d'annonces en cours de traitement",
    "block_title_processed": "Quantité de déchets traités",
    "block_description_processed": "Rapports sur les quantités de déchets(Kg/L) et le nombre d'annonces traités",
    "announce_created_quantity_over_time": "Quantité de déchets ajoutés au cours du temps",
    "announce_created_appearance_over_time": "Nombre d'annonces créées au cours du temps",
    "announce_created_quantity_by_region": "Quantité de déchets ajoutés par région",
    "announce_created_appearance_by_region": "Nombre d'annonces créées par région",
    "announce_created_quantity_by_department": "Quantité de déchets ajoutés par départment",
    "announce_created_appearance_by_department": "Nombre d'annonces créées par départment",
    "announce_created_quantity_by_district": "Quantité de déchets ajoutés par arrondissemen",
    "announce_created_appearance_by_district": "Nombre d'annonces créées par arrondissemen",
    "announce_created_quantity_by_category": "Quantité de déchets ajoutés par catégorie",
    "announce_created_appearance_by_category": "Nombre d'annonces créées par catégorie",
    "announce_created_quantity_by_type": "Quantité de déchets ajoutés par type",
    "announce_created_appearance_by_type": "Nombre d'annonces créées par type",
    "announce_rejected_quantity_over_time": "Quantité de déchets rejetés au cours du temps",
    "announce_rejected_appearance_over_time": "Nombre d'annonces rejetées au cours du temps",
    "announce_rejected_quantity_by_region": "Quantité de déchets rejetés par région",
    "announce_rejected_appearance_by_region": "Nombre d'annonces rejetées par région",
    "announce_rejected_quantity_by_department": "Quantité de déchets rejetés par départment",
    "announce_rejected_appearance_by_department": "Nombre d'annonces rejetées par départment",
    "announce_rejected_quantity_by_district": "Quantité de déchets rejetés par arrondissement",
    "announce_rejected_appearance_by_district": "Nombre d'annonces rejetées par arrondissement",
    "announce_rejected_quantity_by_category": "Quantité de déchets rejetés par catégorie",
    "announce_rejected_appearance_by_category": "Nombre d'annonces rejetées par catégorie",
    "announce_rejected_quantity_by_type": "Quantité de déchets rejetés par type",
    "announce_rejected_appearance_by_type": "Nombre d'annonces rejetées par type",
    "announce_activated_quantity_over_time": "Quantité de déchets validés au cours du temps",
    "announce_activated_appearance_over_time": "Nombre d'annonces validées au cours du temps",
    "announce_activated_quantity_by_region": "Quantité de déchets validés par région",
    "announce_activated_appearance_by_region": "Nombre d'annonces validées par région",
    "announce_activated_quantity_by_department": "Quantité de déchets validés par départment",
    "announce_activated_appearance_by_department": "Nombre d'annonces validées par départment",
    "announce_activated_quantity_by_district": "Quantité de déchets validés par arrondissement",
    "announce_activated_appearance_by_district": "Nombre d'annonces validées par arrondissement",
    "announce_activated_quantity_by_category": "Quantité de déchets validés par catégorie",
    "announce_activated_appearance_by_category": "Nombre d'annonces validées par catégorie",
    "announce_activated_quantity_by_type": "Quantité de déchets validés par type",
    "announce_activated_appearance_by_type": "Nombre d'annonces validées par type",
    "announce_transfering_quantity_over_time": "Quantité de déchets en cours de transfert au cours du temps",
    "announce_transfering_appearance_over_time": "Nombre d'annonces en cours de transfert au cours du temps",
    "announce_transfering_quantity_by_region": "Quantité de déchets en cours de transfert par région",
    "announce_transfering_appearance_by_region": "Nombre d'annonces en cours de transfert par région",
    "announce_transfering_quantity_by_department": "Quantité de déchets en cours de transfert par départment",
    "announce_transfering_appearance_by_department": "Nombre d'annonces en cours de transfert par départment",
    "announce_transfering_quantity_by_district": "Quantité de déchets en cours transfert par arrondissement",
    "announce_transfering_appearance_by_district": "Nombre d'annonces en cours de transfert par arrondissement",
    "announce_transfering_quantity_by_category": "Quantité de déchets en cours de transfert par catégorie",
    "announce_transfering_appearance_by_category": "Nombre d'annonces en cours de transfert par catégorie",
    "announce_transfering_quantity_by_type": "Quantité de déchets en cours de transfert par type",
    "announce_transfering_appearance_by_type": "Nombre d'annonces en cours de transfert par type",
    "announce_transfered_quantity_over_time": "Quantité de déchets transférés au cours du temps",
    "announce_transfered_appearance_over_time": "Nombre d'annonces transférées au cours du temps",
    "announce_transfered_quantity_by_region": "Quantité de déchets transférés par région",
    "announce_transfered_appearance_by_region": "Nombre d'annonces transférées par région",
    "announce_transfered_quantity_by_department": "Quantité de déchets transférés par départment",
    "announce_transfered_appearance_by_department": "Nombre d'annonces transférées par départment",
    "announce_transfered_quantity_by_district": "Quantité de déchets transférés par arrondissement",
    "announce_transfered_appearance_by_district": "Nombre d'annonces transférées par arrondissement",
    "announce_transfered_quantity_by_category": "Quantité de déchets transférés par catégorie",
    "announce_transfered_appearance_by_category": "Nombre d'annonces transférées par catégorie",
    "announce_transfered_quantity_by_type": "Quantité de déchets transférés par type",
    "announce_transfered_appearance_by_type": "Nombre d'annonces transférées par type",
    "announce_processing_quantity_over_time": "Quantité de déchets en cours de traitement au cours du temps",
    "announce_processing_appearance_over_time": "Nombre d'annonces en cours de traitement au cours du temps",
    "announce_processing_quantity_by_region": "Quantité de déchets en cours de traitement par région",
    "announce_processing_appearance_by_region": "Nombre d'annonces en cours de traitement par région",
    "announce_processing_quantity_by_department": "Quantité de déchets en cours de traitement par départment",
    "announce_processing_appearance_by_department": "Nombre d'annonces en cours de traitement par départment",
    "announce_processing_quantity_by_district": "Quantité de déchets en cours de traitement par arrondissement",
    "announce_processing_appearance_by_district": "Nombre d'annonces en cours de traitement par arrondissement",
    "announce_processing_quantity_by_category": "Quantité de déchets en cours de traitement par catégorie",
    "announce_processing_appearance_by_category": "Nombre d'annonces en cours de traitement par catégorie",
    "announce_processing_quantity_by_type": "Quantité de déchets en cours de traitement par type",
    "announce_processing_appearance_by_type": "Nombre d'annonces en cours de traitement par type",
    "announce_processed_quantity_over_time": "Quantité de déchets traités au cours du temps",
    "announce_processed_appearance_over_time": "Nombre d'annonces traitées au cours du temps",
    "announce_processed_quantity_by_region": "Quantité de déchets traités par région",
    "announce_processed_appearance_by_region": "Nombre d'annonces traitées par région",
    "announce_processed_quantity_by_department": "Quantité de déchets traités par départment",
    "announce_processed_appearance_by_department": "Nombre d'annonces traitées par départment",
    "announce_processed_quantity_by_district": "Quantité de déchets traités par arrondissement",
    "announce_processed_appearance_by_district": "Nombre d'annonces traitées par arrondissement",
    "announce_processed_quantity_by_category": "Quantité de déchets traités par catégorie",
    "announce_processed_appearance_by_category": "Nombre d'annonces traitées par catégorie",
    "announce_processed_quantity_by_type": "Quantité de déchets traités par type",
    "announce_processed_appearance_by_type": "Nombre d'annonces traitées par type"
  },
  "ReportFilter": {
    "manage_filters": "Gérer les filtres",
    "fltrT_region": "Régions",
    "fltrT_division": "Départements",
    "fltrT_district": "Arrondissements",
    "fltrT_category": "Catégories",
    "fltrT_type": "Types",
    "fltrT_state": "Etats",
    "select_filter": "Sélectionner un filtre",
    "is": "est",
    "is_not": "n'est pas",
    "remove_filter": "Supprimer",
    "add_filter": "Ajouter"
  },
  "ReportGraph": {
    "graph_cog": "Paramètres du graphique",
    "graph_cog_description": "Modifier le type et les couleurs du graphique",
    "graph_type": "Type de graphique",
    "graph_colos": "Couleurs de l'ensemble de données",
    "select_color": "Sélectionnez la couleur",
    "hide": "Masquer le graphique",
    "graph_stacked": "Empiler l'ensemble de données",
    "stacked": "Empiler l'ensemble de données sur l'axe de {{axis}}",
    "stacked_description": "Si coché, les différents ensembles de données s'additionneront pour former une valeur totale. Sinon, ils seront affichés côte à côte.",
    "graph": "Graphique",
    "loading": "Chargement..."
  },
  "ReportHeader": {
    "is_not": "n'est pas",
    "is": "est",
    "remove_filter": "Supprimer"
  },
  "RoleAssign": {
    "scope_1": "Gobale",
    "scope_2": "Régionale",
    "scope_3": "Départementale",
    "assign_role": "Assigner les rôles",
    "scope": "Portée",
    "pick_scope": "Sélectionner la portée",
    "ok": "Rôle assigné",
    "no_user": "Aucun utilisateur sélectionné",
    "no_region": "Aucune région",
    "no_div": "Aucun départment",
    "no_scope": "Aucune portée",
    "msSearchPlaceholder": "Recherche...",
    "msNoItemsMessage": "Aucun rôle sélectionné",
    "msNoneSelectedMessage": "Aucun sélectionné",
    "msSelectedMessage": "sélectionné(s)",
    "msSelectAllMessage": "Tout sélectionner",
    "msClearAllMessage": "Tout effacer",
    "msDisabledItemsTooltip": "Vous pouvez sélectionner 1 seul élément"
  },
  "RoleBtnRemove": {
    "ok": "Rôle supprimé",
    "confirm_title": "Supprimer le rôle",
    "confirm_desc": "Cette opération est irreversible !!",
    "cancel": "Annuler",
    "delete": "Supprimer"
  },
  "RoleItem": {
    "scope_1": "Gobale",
    "scope_2": "Régionale",
    "scope_3": "Départementale",
    "edit": "Modifer",
    "remove": "Supprimer"
  },
  "RoleList": {
    "name": "Titre",
    "scope": "Portée"
  },
  "RoleSave": {
    "edit_title": "Modifier le rôle",
    "create_title": "Ajouter un rôle",
    "title": "Titre",
    "scope": "Portée",
    "no_scope": "Aucune portée",
    "select_scope": "Sélectionner la portée",
    "create_ok": "Nouveau rôle ajouté",
    "edited_ok": "Rôle modifié",
    "scope_description_1": "Les permissions définies auront une portée globale",
    "scope_description_2": "Les permissions définies auront une portée limitée aux régions associées",
    "scope_description_3": "Les permissions définies auront une portée limitée aux départments associés",
    "scope_1": "Gobale",
    "scope_2": "Régionale",
    "scope_3": "Départementale"
  },
  "SearchPopover": {
    "search": "Recherche",
    "selected_many": "{{selected}} et {{count}} de plus",
    "selected_many_plural": "",
    "confirm": "Confirmer"
  },
  "ShareModal": {
    "description": "Partager l'annonce",
    "share": "Partager",
    "share_email": "Envoyer par email",
    "share_facebook": "Partager sur facebook",
    "share_twitter": "Partager sur twitter",
    "share_whatsapp": "Partager sur whatsapp",
    "share_copy_link": "Copier le lien",
    "link_copied": "Lien copié !!"
  },
  "SocietyBtnRemove": {
    "ok": "Société supprimée",
    "confirm_title": "Supprimer la société",
    "confirm_desc": "Cette opération est irreversible !!",
    "cancel": "Annuler",
    "delete": "Supprimer"
  },
  "SocietyItem": {
    "edit": "Modifier",
    "remove": "Supprimer",
    "no": "No",
    "valid": "Valide",
    "invalid": "Invalide",
    "date": "Date"
  },
  "SocietyList": {
    "details": "Détails",
    "licenses": "Permis"
  },
  "SocietySave": {
    "edit_title": "Ajouter une société",
    "create_title": "Modifier la société",
    "name": "Nom",
    "niu": "Numéro d'identifiant unique",
    "lics": "Permis",
    "rm_license": "Retirer",
    "permitNo": "Numéro du permit environnemental",
    "start": "Date de validité du permis [début]",
    "end": "Date de validité de permis [fin]",
    "specialities": "Spécialités",
    "add_license": "Ajouter",
    "address": "Addresse",
    "city": "Ville",
    "pobox": "Boite postale",
    "phone": "Téléphone",
    "phone2": "Téléphone 2",
    "email": "Email",
    "description": "Description",
    "edited_ok": "Société enregistrée",
    "created_ok": "Nouvelle société ajoutée"
  },
  "TransitSave": {
    "edit_title": "Modifier le transit",
    "add_title": "Ajouter le transit",
    "prv_transit": "Transit précédent",
    "location": "Location",
    "date": "Date",
    "conformity": "Observations sur la quantité et la qualité des déchets reçus",
    "conformity_ok": "Conforme",
    "conformity_not_ok": "Non conforme",
    "observations": "Motif de non conformité",
    "is_last": "Est ce le dernier transit ?",
    "yep": "Oui",
    "nope": "Non",
    "next_transit": "Prochain transit",
    "city": "Ville",
    "address": "Addresse (Quartier)",
    "edited_error": "Erreur lors de la modification du transit",
    "edited_ok": "Transit modifié",
    "created_error": "Erreur lors de la création du transit",
    "created_ok": "Nouveau Transit ajouté",
    "conformity_obs_req": "Préciser le motif de non conformité"
  },
  "TypeBtnRemove": {
    "ok": "",
    "confirm_title": "",
    "confirm_desc": "",
    "cancel": "",
    "delete": ""
  },
  "TypeItem": {
    "edit": "Modifier",
    "remove": "Supprimer"
  },
  "TypeList": {
    "name": "Nom",
    "code": "Code",
    "unity": "Unité",
    "category": "Catégorie",
    "description": "Description"
  },
  "TypeSave": {
    "edit_title": "Modifier le type",
    "create_title": "Ajouter un type",
    "name": "Nom",
    "code": "Code",
    "description": "Description",
    "unity": "Unité",
    "select_unity": "Sélectionner l'unité",
    "edited_ok": "Type modifié",
    "created_ok": "Nouveau type ajouté"
  },
  "Unity": {
    " ": ""
  }
}
  export default fr
  