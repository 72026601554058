/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_MeInterests_QueryVariables = {
    first?: number | null;
    after?: string | null;
    bourseId: string;
    regionId?: string | null;
    departmentId?: string | null;
    districtId?: string | null;
    categoryId?: string | null;
    typeId?: string | null;
    userId?: string | null;
    search?: string | null;
};
export type routes_MeInterests_QueryResponse = {
    readonly data: {
        readonly " $fragmentRefs": FragmentRefs<"MeInterests_data">;
    } | null;
};
export type routes_MeInterests_Query = {
    readonly response: routes_MeInterests_QueryResponse;
    readonly variables: routes_MeInterests_QueryVariables;
};



/*
query routes_MeInterests_Query(
  $first: Int
  $after: String
  $bourseId: ID!
  $regionId: ID
  $departmentId: ID
  $districtId: ID
  $categoryId: ID
  $typeId: ID
  $userId: ID
  $search: String
) {
  data: viewer {
    ...MeInterests_data_jI7TH
  }
}

fragment AnnounceItemPlace_place on Location {
  region {
    id
    name
    code
  }
  department {
    id
    name
  }
  district {
    id
    name
  }
  address
  city
  longitude
  lattitude
}

fragment AnnounceItemTypeDescription_announce on Announce {
  qty
  type {
    id
    name
    unity
  }
  category {
    id
    name
  }
}

fragment InterestItem_interest on Interest {
  createdAt
  from {
    name
    phone
    email
    location {
      ...AnnounceItemPlace_place
    }
  }
  message
  announce {
    _id
    id
    name
    qty
    ...AnnounceItemTypeDescription_announce
    location {
      ...AnnounceItemPlace_place
    }
  }
}

fragment InterestList_bourse_4uPV9z on Bourse {
  interests(first: $first, after: $after, regionId: $regionId, departmentId: $departmentId, districtId: $districtId, categoryId: $categoryId, typeId: $typeId, userId: $userId, search: $search) {
    totalCount
    edgeCount
    edges {
      node {
        id
        ...InterestItem_interest
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment MeInterests_data_jI7TH on Viewer {
  bourse(id: $bourseId) {
    name
    ...InterestList_bourse_4uPV9z
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bourseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "departmentId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "districtId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "typeId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v10 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v11 = {
  "kind": "Variable",
  "name": "categoryId",
  "variableName": "categoryId"
},
v12 = {
  "kind": "Variable",
  "name": "departmentId",
  "variableName": "departmentId"
},
v13 = {
  "kind": "Variable",
  "name": "districtId",
  "variableName": "districtId"
},
v14 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v15 = {
  "kind": "Variable",
  "name": "regionId",
  "variableName": "regionId"
},
v16 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v17 = {
  "kind": "Variable",
  "name": "typeId",
  "variableName": "typeId"
},
v18 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v20 = [
  (v10/*: any*/),
  (v11/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/)
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = [
  (v21/*: any*/),
  (v19/*: any*/)
],
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Location",
  "kind": "LinkedField",
  "name": "location",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Region",
      "kind": "LinkedField",
      "name": "region",
      "plural": false,
      "selections": [
        (v21/*: any*/),
        (v19/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Department",
      "kind": "LinkedField",
      "name": "department",
      "plural": false,
      "selections": (v22/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "District",
      "kind": "LinkedField",
      "name": "district",
      "plural": false,
      "selections": (v22/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lattitude",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_MeInterests_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v10/*: any*/),
              {
                "kind": "Variable",
                "name": "bourseId",
                "variableName": "bourseId"
              },
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "MeInterests_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_MeInterests_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "bourseId"
              }
            ],
            "concreteType": "Bourse",
            "kind": "LinkedField",
            "name": "bourse",
            "plural": false,
            "selections": [
              (v19/*: any*/),
              {
                "alias": null,
                "args": (v20/*: any*/),
                "concreteType": "InterestConnection",
                "kind": "LinkedField",
                "name": "interests",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "edgeCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InterestEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Interest",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InterestFrom",
                            "kind": "LinkedField",
                            "name": "from",
                            "plural": false,
                            "selections": [
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "phone",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Announce",
                            "kind": "LinkedField",
                            "name": "announce",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "_id",
                                "storageKey": null
                              },
                              (v21/*: any*/),
                              (v19/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "qty",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CatType",
                                "kind": "LinkedField",
                                "name": "type",
                                "plural": false,
                                "selections": [
                                  (v21/*: any*/),
                                  (v19/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "unity",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Category",
                                "kind": "LinkedField",
                                "name": "category",
                                "plural": false,
                                "selections": (v22/*: any*/),
                                "storageKey": null
                              },
                              (v23/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v20/*: any*/),
                "filters": [
                  "status",
                  "announceId",
                  "regionId",
                  "departmentId",
                  "districtId",
                  "categoryId",
                  "typeId",
                  "userId",
                  "search"
                ],
                "handle": "connection",
                "key": "InterestList_interests",
                "kind": "LinkedHandle",
                "name": "interests"
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "00c33f9e09c9ee968b6a6e57af6549fd",
    "id": null,
    "metadata": {},
    "name": "routes_MeInterests_Query",
    "operationKind": "query",
    "text": "query routes_MeInterests_Query(\n  $first: Int\n  $after: String\n  $bourseId: ID!\n  $regionId: ID\n  $departmentId: ID\n  $districtId: ID\n  $categoryId: ID\n  $typeId: ID\n  $userId: ID\n  $search: String\n) {\n  data: viewer {\n    ...MeInterests_data_jI7TH\n  }\n}\n\nfragment AnnounceItemPlace_place on Location {\n  region {\n    id\n    name\n    code\n  }\n  department {\n    id\n    name\n  }\n  district {\n    id\n    name\n  }\n  address\n  city\n  longitude\n  lattitude\n}\n\nfragment AnnounceItemTypeDescription_announce on Announce {\n  qty\n  type {\n    id\n    name\n    unity\n  }\n  category {\n    id\n    name\n  }\n}\n\nfragment InterestItem_interest on Interest {\n  createdAt\n  from {\n    name\n    phone\n    email\n    location {\n      ...AnnounceItemPlace_place\n    }\n  }\n  message\n  announce {\n    _id\n    id\n    name\n    qty\n    ...AnnounceItemTypeDescription_announce\n    location {\n      ...AnnounceItemPlace_place\n    }\n  }\n}\n\nfragment InterestList_bourse_4uPV9z on Bourse {\n  interests(first: $first, after: $after, regionId: $regionId, departmentId: $departmentId, districtId: $districtId, categoryId: $categoryId, typeId: $typeId, userId: $userId, search: $search) {\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        ...InterestItem_interest\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment MeInterests_data_jI7TH on Viewer {\n  bourse(id: $bourseId) {\n    name\n    ...InterestList_bourse_4uPV9z\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e42fe6fcac10cb6e6d4cc8b16d11211e';
export default node;
