/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_BoardAnnounce_QueryVariables = {
    id: string;
    isAdmin: boolean;
};
export type routes_BoardAnnounce_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"BoardAnnounce_viewer">;
    } | null;
};
export type routes_BoardAnnounce_Query = {
    readonly response: routes_BoardAnnounce_QueryResponse;
    readonly variables: routes_BoardAnnounce_QueryVariables;
};



/*
query routes_BoardAnnounce_Query(
  $id: ID!
  $isAdmin: Boolean!
) {
  viewer {
    ...BoardAnnounce_viewer_2ryQD8
  }
}

fragment AnnounceDetail_viewer_2ryQD8 on Viewer {
  me {
    id
    profile {
      fullName
      email
      phone
    }
  }
  node(id: $id) {
    __typename
    ... on Announce {
      id
      name
      description
      createdBy {
        id
        ...UserProfileLink_user
        profile {
          fullName
          image
          email
          phone
        }
      }
      nInterests
      manifest @include(if: $isAdmin) {
        id
        wInstructions
        wInstructionsOther
        destination {
          bourseId
          regionId
          departmentId
          ...AnnounceItemPlace_place
        }
      }
      ...AnnounceItemTypeDescription_announce
      location {
        ...AnnounceItemPlace_place
        bourseId
        regionId
        departmentId
        longitude
        lattitude
      }
      state
      images
    }
    id
  }
}

fragment AnnounceItemPlace_place on Location {
  region {
    id
    name
    code
  }
  department {
    id
    name
  }
  district {
    id
    name
  }
  address
  city
  longitude
  lattitude
}

fragment AnnounceItemTypeDescription_announce on Announce {
  qty
  type {
    id
    name
    unity
  }
  category {
    id
    name
  }
}

fragment BoardAnnounce_viewer_2ryQD8 on Viewer {
  ...AnnounceDetail_viewer_2ryQD8
  announce: node(id: $id) {
    __typename
    ... on Announce {
      name
    }
    id
  }
}

fragment UserProfileLink_user on User {
  id
  profile {
    fullName
    image
    email
    phone
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isAdmin"
  }
],
v1 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v6 = [
  (v1/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Region",
  "kind": "LinkedField",
  "name": "region",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Department",
  "kind": "LinkedField",
  "name": "department",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "District",
  "kind": "LinkedField",
  "name": "district",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "longitude",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lattitude",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bourseId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "regionId",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "departmentId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_BoardAnnounce_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v1/*: any*/),
              {
                "kind": "Variable",
                "name": "isAdmin",
                "variableName": "isAdmin"
              }
            ],
            "kind": "FragmentSpread",
            "name": "BoardAnnounce_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_BoardAnnounce_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Profile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "createdBy",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Profile",
                        "kind": "LinkedField",
                        "name": "profile",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "image",
                            "storageKey": null
                          },
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nInterests",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qty",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CatType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unity",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Category",
                    "kind": "LinkedField",
                    "name": "category",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Location",
                    "kind": "LinkedField",
                    "name": "location",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "images",
                    "storageKey": null
                  },
                  {
                    "condition": "isAdmin",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Manifest",
                        "kind": "LinkedField",
                        "name": "manifest",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "wInstructions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "wInstructionsOther",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Location",
                            "kind": "LinkedField",
                            "name": "destination",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "type": "Announce",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "announce",
            "args": (v6/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v8/*: any*/)
                ],
                "type": "Announce",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "972a6c066ce60549177ec671fb3649bf",
    "id": null,
    "metadata": {},
    "name": "routes_BoardAnnounce_Query",
    "operationKind": "query",
    "text": "query routes_BoardAnnounce_Query(\n  $id: ID!\n  $isAdmin: Boolean!\n) {\n  viewer {\n    ...BoardAnnounce_viewer_2ryQD8\n  }\n}\n\nfragment AnnounceDetail_viewer_2ryQD8 on Viewer {\n  me {\n    id\n    profile {\n      fullName\n      email\n      phone\n    }\n  }\n  node(id: $id) {\n    __typename\n    ... on Announce {\n      id\n      name\n      description\n      createdBy {\n        id\n        ...UserProfileLink_user\n        profile {\n          fullName\n          image\n          email\n          phone\n        }\n      }\n      nInterests\n      manifest @include(if: $isAdmin) {\n        id\n        wInstructions\n        wInstructionsOther\n        destination {\n          bourseId\n          regionId\n          departmentId\n          ...AnnounceItemPlace_place\n        }\n      }\n      ...AnnounceItemTypeDescription_announce\n      location {\n        ...AnnounceItemPlace_place\n        bourseId\n        regionId\n        departmentId\n        longitude\n        lattitude\n      }\n      state\n      images\n    }\n    id\n  }\n}\n\nfragment AnnounceItemPlace_place on Location {\n  region {\n    id\n    name\n    code\n  }\n  department {\n    id\n    name\n  }\n  district {\n    id\n    name\n  }\n  address\n  city\n  longitude\n  lattitude\n}\n\nfragment AnnounceItemTypeDescription_announce on Announce {\n  qty\n  type {\n    id\n    name\n    unity\n  }\n  category {\n    id\n    name\n  }\n}\n\nfragment BoardAnnounce_viewer_2ryQD8 on Viewer {\n  ...AnnounceDetail_viewer_2ryQD8\n  announce: node(id: $id) {\n    __typename\n    ... on Announce {\n      name\n    }\n    id\n  }\n}\n\nfragment UserProfileLink_user on User {\n  id\n  profile {\n    fullName\n    image\n    email\n    phone\n  }\n}\n"
  }
};
})();
(node as any).hash = '9b9ccebc737caf838987ded146cbf93f';
export default node;
