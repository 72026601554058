/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_Admin_QueryVariables = {};
export type routes_Admin_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"Admin_viewer">;
    } | null;
};
export type routes_Admin_Query = {
    readonly response: routes_Admin_QueryResponse;
    readonly variables: routes_Admin_QueryVariables;
};



/*
query routes_Admin_Query {
  viewer {
    ...Admin_viewer
  }
}

fragment Admin_viewer on Viewer {
  me {
    id
  }
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Admin_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Admin_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_Admin_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c15088c046b3d54d681df7d93d78428e",
    "id": null,
    "metadata": {},
    "name": "routes_Admin_Query",
    "operationKind": "query",
    "text": "query routes_Admin_Query {\n  viewer {\n    ...Admin_viewer\n  }\n}\n\nfragment Admin_viewer on Viewer {\n  me {\n    id\n  }\n}\n"
  }
};
(node as any).hash = '209ad0441045e87a77054fde261a4819';
export default node;
