import qs from "query-string"

const Key2Query: Record<string, string> = {
  bourse: "bourseId",
  region: "regionId",
  division: "departmentId",
  department: "departmentId",
  district: "districtId",
  type: "typeId",
  category: "categoryId",
  state: "state",
  status: "status",
  society: "societyId",
  trSociety: "transportSocietyId",
  desSociety: "designatedFSocietyId",

  query: "search",
}
export default function extractKeysFromLocation(location: Location) {
  const search = qs.parse(location.search)
  const query: Record<string, string> = {}
  Object.keys(Key2Query).forEach((key) =>
    search[key] ? (query[Key2Query[key]] = search[key] as string) : null
  )
  return query
}
