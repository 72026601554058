/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_AuthSignout_QueryVariables = {};
export type routes_AuthSignout_QueryResponse = {
    readonly data: {
        readonly " $fragmentRefs": FragmentRefs<"AuthSignout_data">;
    } | null;
};
export type routes_AuthSignout_Query = {
    readonly response: routes_AuthSignout_QueryResponse;
    readonly variables: routes_AuthSignout_QueryVariables;
};



/*
query routes_AuthSignout_Query {
  data: viewer {
    ...AuthSignout_data
  }
}

fragment AuthSignout_data on Viewer {
  me {
    id
  }
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AuthSignout_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuthSignout_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_AuthSignout_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "407fa79142dcb03624ee413b8116527a",
    "id": null,
    "metadata": {},
    "name": "routes_AuthSignout_Query",
    "operationKind": "query",
    "text": "query routes_AuthSignout_Query {\n  data: viewer {\n    ...AuthSignout_data\n  }\n}\n\nfragment AuthSignout_data on Viewer {\n  me {\n    id\n  }\n}\n"
  }
};
(node as any).hash = 'e1a9af313ca4a5a320aad6b6d3147c40';
export default node;
