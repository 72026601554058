/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_AuthSendResetPwd_QueryVariables = {};
export type routes_AuthSendResetPwd_QueryResponse = {
    readonly data: {
        readonly " $fragmentRefs": FragmentRefs<"AuthSendResetPwd_data">;
    } | null;
};
export type routes_AuthSendResetPwd_Query = {
    readonly response: routes_AuthSendResetPwd_QueryResponse;
    readonly variables: routes_AuthSendResetPwd_QueryVariables;
};



/*
query routes_AuthSendResetPwd_Query {
  data: viewer {
    ...AuthSendResetPwd_data
  }
}

fragment AuthSendResetPwd_data on Viewer {
  me {
    profile {
      fullName
    }
    id
  }
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AuthSendResetPwd_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuthSendResetPwd_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_AuthSendResetPwd_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Profile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dda458c598b6703cfc41f47577ce87e9",
    "id": null,
    "metadata": {},
    "name": "routes_AuthSendResetPwd_Query",
    "operationKind": "query",
    "text": "query routes_AuthSendResetPwd_Query {\n  data: viewer {\n    ...AuthSendResetPwd_data\n  }\n}\n\nfragment AuthSendResetPwd_data on Viewer {\n  me {\n    profile {\n      fullName\n    }\n    id\n  }\n}\n"
  }
};
(node as any).hash = '9f268a026f677a401fdf15ded1d48f76';
export default node;
