/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_Home_QueryVariables = {
    bourseId: string;
};
export type routes_Home_QueryResponse = {
    readonly me: {
        readonly id: string;
    } | null;
    readonly bourse: {
        readonly " $fragmentRefs": FragmentRefs<"Home_bourse">;
    } | null;
};
export type routes_Home_Query = {
    readonly response: routes_Home_QueryResponse;
    readonly variables: routes_Home_QueryVariables;
};



/*
query routes_Home_Query(
  $bourseId: ID!
) {
  me {
    id
  }
  bourse: node(id: $bourseId) {
    __typename
    ...Home_bourse
    id
  }
}

fragment Home_bourse on Bourse {
  name
  country
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bourseId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "me",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "bourseId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Home_Query",
    "selections": [
      (v2/*: any*/),
      {
        "alias": "bourse",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Home_bourse"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routes_Home_Query",
    "selections": [
      (v2/*: any*/),
      {
        "alias": "bourse",
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "country",
                "storageKey": null
              }
            ],
            "type": "Bourse",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ec326b57e1714187377c913d1718f4e",
    "id": null,
    "metadata": {},
    "name": "routes_Home_Query",
    "operationKind": "query",
    "text": "query routes_Home_Query(\n  $bourseId: ID!\n) {\n  me {\n    id\n  }\n  bourse: node(id: $bourseId) {\n    __typename\n    ...Home_bourse\n    id\n  }\n}\n\nfragment Home_bourse on Bourse {\n  name\n  country\n}\n"
  }
};
})();
(node as any).hash = '339d7db030c8d0f6fdded5b6a490ddb9';
export default node;
