import {
  Network,
  Observable,
  RequestParameters,
  Variables,
  GraphQLResponse,
  Environment,
  Store,
  RecordSource,
  FetchFunction,
} from "relay-runtime"
import { createClient } from "graphql-ws"
import { getCookie, COOKIE } from "./cookie"

function getAuthorizationHeader(): Record<string, string> {
  const token = getCookie(COOKIE)
  console.log({ Authorization: `Bearer ${token}` })
  if (!token) {
    return {}
  }
  return {
    Authorization: `Bearer ${token}`,
  }
}

const subscriptionsClient = createClient({
  url: `ws://${process.env.REACT_APP_GRAPHQL_SERVER_API}`,
  connectionParams: () => getAuthorizationHeader(),
})

const fetchGraphQL: FetchFunction = function (operation, variables) {
  const server =
    (process.env.REACT_APP_SERVER_API as string) || window.location.origin
  console.log({ operation, variables })
  return fetch(`${server}/graphql`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...getAuthorizationHeader(),
    },
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
  }).then((response) => {
    return response.json()
  })
}

function fetchOrSubscribe(operation: RequestParameters, variables: Variables) {
  return Observable.create<GraphQLResponse>((sink) => {
    if (!operation.text) {
      return sink.error(new Error("Operation text cannot be empty"))
    }
    return subscriptionsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      {
        ...sink,
        error: (err) => {
          if (err instanceof Error) {
            sink.error(err)
          } else if (err instanceof CloseEvent) {
            sink.error(
              new Error(
                `Socket closed with event ${err.code}` + err.reason
                  ? `: ${err.reason}` // reason will be available on clean closes
                  : ""
              )
            )
          } else {
            sink.error(
              new Error(
                (err as Error[]).map(({ message }) => message).join(", ")
              )
            )
          }
        },
      }
    )
  })
}

export const network = Network.create(fetchGraphQL, fetchOrSubscribe)

export default function getEnvironment() {
  return new Environment({
    network,
    store: new Store(new RecordSource()),
  })
}
