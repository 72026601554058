/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SortOrderEnum = "ASC" | "DESC" | "%future added value";
export type AgentOrdering = {
    createdAt?: SortOrderEnum | null;
};
export type routes_AdminAgents_QueryVariables = {
    first?: number | null;
    after?: string | null;
    status?: string | null;
    bourseId: string;
    search?: string | null;
    orderBy?: AgentOrdering | null;
};
export type routes_AdminAgents_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"AdminAgents_viewer">;
    } | null;
};
export type routes_AdminAgents_Query = {
    readonly response: routes_AdminAgents_QueryResponse;
    readonly variables: routes_AdminAgents_QueryVariables;
};



/*
query routes_AdminAgents_Query(
  $first: Int
  $after: String
  $status: String
  $bourseId: ID!
  $search: String
  $orderBy: AgentOrdering
) {
  viewer {
    ...AdminAgents_viewer_2Y7fB1
  }
}

fragment AdminAgents_viewer_2Y7fB1 on Viewer {
  bourse(id: $bourseId) {
    name
    ...AgentAdminList_bourse_38kkKn
    id
  }
}

fragment AgentAdminItem_agent on Agent {
  id
  status
  createdAt
  user {
    id
    isVerified
    profile {
      fullName
      email
      image
      phone
      matricule
    }
  }
  rolesList {
    id
    title
    scope
  }
  subsList {
    id
    region {
      id
      code
    }
    department {
      id
      name
    }
    rolesList {
      id
      title
      scope
    }
  }
}

fragment AgentAdminList_bourse_38kkKn on Bourse {
  agents(first: $first, after: $after, status: $status, search: $search, orderBy: $orderBy, specific: true) {
    totalCount
    edgeCount
    edges {
      node {
        id
        ...AgentAdminItem_agent
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bourseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v9 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v10 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  {
    "kind": "Literal",
    "name": "specific",
    "value": true
  },
  (v10/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "rolesList",
  "plural": true,
  "selections": [
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scope",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminAgents_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "bourseId",
                "variableName": "bourseId"
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AdminAgents_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_AdminAgents_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "bourseId"
              }
            ],
            "concreteType": "Bourse",
            "kind": "LinkedField",
            "name": "bourse",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "AgentConnection",
                "kind": "LinkedField",
                "name": "agents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "edgeCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Agent",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isVerified",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Profile",
                                "kind": "LinkedField",
                                "name": "profile",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "fullName",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "email",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "image",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "phone",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "matricule",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Agent",
                            "kind": "LinkedField",
                            "name": "subsList",
                            "plural": true,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Region",
                                "kind": "LinkedField",
                                "name": "region",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "code",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Department",
                                "kind": "LinkedField",
                                "name": "department",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v11/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [
                  "status"
                ],
                "handle": "connection",
                "key": "AgentAdminList_agents",
                "kind": "LinkedHandle",
                "name": "agents"
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e45424e261b9ccd4145827de35ba8ac8",
    "id": null,
    "metadata": {},
    "name": "routes_AdminAgents_Query",
    "operationKind": "query",
    "text": "query routes_AdminAgents_Query(\n  $first: Int\n  $after: String\n  $status: String\n  $bourseId: ID!\n  $search: String\n  $orderBy: AgentOrdering\n) {\n  viewer {\n    ...AdminAgents_viewer_2Y7fB1\n  }\n}\n\nfragment AdminAgents_viewer_2Y7fB1 on Viewer {\n  bourse(id: $bourseId) {\n    name\n    ...AgentAdminList_bourse_38kkKn\n    id\n  }\n}\n\nfragment AgentAdminItem_agent on Agent {\n  id\n  status\n  createdAt\n  user {\n    id\n    isVerified\n    profile {\n      fullName\n      email\n      image\n      phone\n      matricule\n    }\n  }\n  rolesList {\n    id\n    title\n    scope\n  }\n  subsList {\n    id\n    region {\n      id\n      code\n    }\n    department {\n      id\n      name\n    }\n    rolesList {\n      id\n      title\n      scope\n    }\n  }\n}\n\nfragment AgentAdminList_bourse_38kkKn on Bourse {\n  agents(first: $first, after: $after, status: $status, search: $search, orderBy: $orderBy, specific: true) {\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        ...AgentAdminItem_agent\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '783ddf9cf95d58b8e174feab94c7ae67';
export default node;
