/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_MeAccount_QueryVariables = {};
export type routes_MeAccount_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"MeAccount_viewer">;
    } | null;
};
export type routes_MeAccount_Query = {
    readonly response: routes_MeAccount_QueryResponse;
    readonly variables: routes_MeAccount_QueryVariables;
};



/*
query routes_MeAccount_Query {
  viewer {
    ...MeAccount_viewer
  }
}

fragment AccountEditPassword_user on User {
  id
}

fragment AccountEditProfile_user on User {
  id
  profile {
    lastName
    firstName
    image
    email
    phone
    matricule
    niu
    language
  }
}

fragment Account_viewer on Viewer {
  me {
    ...AccountEditProfile_user
    ...AccountEditPassword_user
    id
  }
}

fragment MeAccount_viewer on Viewer {
  ...Account_viewer
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_MeAccount_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MeAccount_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_MeAccount_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Profile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "matricule",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "niu",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "language",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ee1447939ac60eb432a1279b4d26414",
    "id": null,
    "metadata": {},
    "name": "routes_MeAccount_Query",
    "operationKind": "query",
    "text": "query routes_MeAccount_Query {\n  viewer {\n    ...MeAccount_viewer\n  }\n}\n\nfragment AccountEditPassword_user on User {\n  id\n}\n\nfragment AccountEditProfile_user on User {\n  id\n  profile {\n    lastName\n    firstName\n    image\n    email\n    phone\n    matricule\n    niu\n    language\n  }\n}\n\nfragment Account_viewer on Viewer {\n  me {\n    ...AccountEditProfile_user\n    ...AccountEditPassword_user\n    id\n  }\n}\n\nfragment MeAccount_viewer on Viewer {\n  ...Account_viewer\n}\n"
  }
};
(node as any).hash = '4d13ffb4697c00aa16bb72d96d0c6a24';
export default node;
