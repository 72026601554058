/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_AdminTypes_QueryVariables = {
    first?: number | null;
    after?: string | null;
    status?: string | null;
    bourseId: string;
    categoryId?: string | null;
    search?: string | null;
};
export type routes_AdminTypes_QueryResponse = {
    readonly data: {
        readonly " $fragmentRefs": FragmentRefs<"AdminTypes_data">;
    } | null;
};
export type routes_AdminTypes_Query = {
    readonly response: routes_AdminTypes_QueryResponse;
    readonly variables: routes_AdminTypes_QueryVariables;
};



/*
query routes_AdminTypes_Query(
  $first: Int
  $after: String
  $status: String
  $bourseId: ID!
  $categoryId: ID
  $search: String
) {
  data: viewer {
    ...AdminTypes_data_btRpD
  }
}

fragment AdminTypes_data_btRpD on Viewer {
  bourse(id: $bourseId) {
    name
    ...TypeList_bourse_6fiMV
    id
  }
}

fragment TypeItem_type on CatType {
  id
  name
  code
  description
  unity
  category {
    id
    name
  }
}

fragment TypeList_bourse_6fiMV on Bourse {
  types(first: $first, after: $after, status: $status, categoryId: $categoryId, search: $search) {
    totalCount
    edgeCount
    edges {
      node {
        id
        ...TypeItem_type
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bourseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categoryId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v7 = {
  "kind": "Variable",
  "name": "categoryId",
  "variableName": "categoryId"
},
v8 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v9 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v10 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminTypes_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "bourseId",
                "variableName": "bourseId"
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AdminTypes_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_AdminTypes_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "bourseId"
              }
            ],
            "concreteType": "Bourse",
            "kind": "LinkedField",
            "name": "bourse",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "CatTypeConnection",
                "kind": "LinkedField",
                "name": "types",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "edgeCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CatTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CatType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unity",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Category",
                            "kind": "LinkedField",
                            "name": "category",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [
                  "status",
                  "categoryId",
                  "search"
                ],
                "handle": "connection",
                "key": "TypeList_types",
                "kind": "LinkedHandle",
                "name": "types"
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78b85bfd2c9b5aea90eddd438a27f4f5",
    "id": null,
    "metadata": {},
    "name": "routes_AdminTypes_Query",
    "operationKind": "query",
    "text": "query routes_AdminTypes_Query(\n  $first: Int\n  $after: String\n  $status: String\n  $bourseId: ID!\n  $categoryId: ID\n  $search: String\n) {\n  data: viewer {\n    ...AdminTypes_data_btRpD\n  }\n}\n\nfragment AdminTypes_data_btRpD on Viewer {\n  bourse(id: $bourseId) {\n    name\n    ...TypeList_bourse_6fiMV\n    id\n  }\n}\n\nfragment TypeItem_type on CatType {\n  id\n  name\n  code\n  description\n  unity\n  category {\n    id\n    name\n  }\n}\n\nfragment TypeList_bourse_6fiMV on Bourse {\n  types(first: $first, after: $after, status: $status, categoryId: $categoryId, search: $search) {\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        ...TypeItem_type\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5928e63aeccc5a8b603a71169d1f25a2';
export default node;
