/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_Me_QueryVariables = {};
export type routes_Me_QueryResponse = {
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"Me_user">;
    } | null;
};
export type routes_Me_Query = {
    readonly response: routes_Me_QueryResponse;
    readonly variables: routes_Me_QueryVariables;
};



/*
query routes_Me_Query {
  user: viewer {
    ...Me_user
  }
}

fragment AnnounceBoardItem_user on User {
  id
  profile {
    fullName
    email
    phone
  }
}

fragment AnnounceBoardList_user on User {
  ...AnnounceBoardItem_user
}

fragment Me_user on Viewer {
  me {
    id
    profile {
      fullName
      image
    }
    ...AnnounceBoardList_user
  }
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Me_Query",
    "selections": [
      {
        "alias": "user",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Me_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_Me_Query",
    "selections": [
      {
        "alias": "user",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Profile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9b9e05ead076b0040578b8eab6a87a2",
    "id": null,
    "metadata": {},
    "name": "routes_Me_Query",
    "operationKind": "query",
    "text": "query routes_Me_Query {\n  user: viewer {\n    ...Me_user\n  }\n}\n\nfragment AnnounceBoardItem_user on User {\n  id\n  profile {\n    fullName\n    email\n    phone\n  }\n}\n\nfragment AnnounceBoardList_user on User {\n  ...AnnounceBoardItem_user\n}\n\nfragment Me_user on Viewer {\n  me {\n    id\n    profile {\n      fullName\n      image\n    }\n    ...AnnounceBoardList_user\n  }\n}\n"
  }
};
(node as any).hash = 'b1eaa8c60f7eaf757f4fc4955591de6c';
export default node;
