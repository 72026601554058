import i18n from "i18next"
import { initReactI18next } from "react-i18next"
//import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import LocalBackend from "./LocalBackend"

i18n
  .use(LocalBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    lng: localStorage.getItem("lng") || "fr",

    fallbackLng: "fr",
    defaultNS: "Global",
    //preload: ["fr", "en"],

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
