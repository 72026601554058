/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_Board_QueryVariables = {};
export type routes_Board_QueryResponse = {
    readonly data: {
        readonly " $fragmentRefs": FragmentRefs<"Board_data">;
    } | null;
};
export type routes_Board_Query = {
    readonly response: routes_Board_QueryResponse;
    readonly variables: routes_Board_QueryVariables;
};



/*
query routes_Board_Query {
  data: viewer {
    ...Board_data
  }
}

fragment Board_data on Viewer {
  me {
    ...HeaderBoard_me
    agents {
      edges {
        node {
          id
          __typename
          location {
            bourseId
            regionId
            departmentId
          }
          rolesList {
            id
            scope
            permissions
          }
        }
      }
    }
    id
  }
}

fragment HeaderBoardUserIn_me on User {
  id
  profile {
    fullName
    image
  }
}

fragment HeaderBoardUserOut_me on User {
  id
}

fragment HeaderBoard_me on User {
  ...HeaderBoardUserIn_me
  ...HeaderBoardUserOut_me
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_Board_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Board_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routes_Board_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "me",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Profile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AgentConnection",
                "kind": "LinkedField",
                "name": "agents",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AgentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Agent",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Location",
                            "kind": "LinkedField",
                            "name": "location",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bourseId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "regionId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "departmentId",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "rolesList",
                            "plural": true,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "scope",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "permissions",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26416956c01be318102f3046a8bcc273",
    "id": null,
    "metadata": {},
    "name": "routes_Board_Query",
    "operationKind": "query",
    "text": "query routes_Board_Query {\n  data: viewer {\n    ...Board_data\n  }\n}\n\nfragment Board_data on Viewer {\n  me {\n    ...HeaderBoard_me\n    agents {\n      edges {\n        node {\n          id\n          __typename\n          location {\n            bourseId\n            regionId\n            departmentId\n          }\n          rolesList {\n            id\n            scope\n            permissions\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment HeaderBoardUserIn_me on User {\n  id\n  profile {\n    fullName\n    image\n  }\n}\n\nfragment HeaderBoardUserOut_me on User {\n  id\n}\n\nfragment HeaderBoard_me on User {\n  ...HeaderBoardUserIn_me\n  ...HeaderBoardUserOut_me\n}\n"
  }
};
})();
(node as any).hash = '98c0d8f31bf40f73ab3c42ccc91d3ccc';
export default node;
