import React from "react"
import graphql from "babel-plugin-relay/macro"
import Route from "found/Route"
import makeRouteConfig from "found/makeRouteConfig"
import { lazy } from "@loadable/component"

import extractKeysFromLocation from "helpers/extractKeysFromLocation"

const Auth = lazy(() => import("./pages/Auth"))
const AuthSignin = lazy(() => import("./pages/AuthSignin"))
const AuthSignup = lazy(() => import("./pages/AuthSignup"))
const AuthSignout = lazy(() => import("./pages/AuthSignout"))
const AuthSendResetPwd = lazy(() => import("./pages/AuthSendResetPwd"))
const AuthResetPwd = lazy(() => import("./pages/AuthResetPwd"))
const AuthVerifySignupSetPassword = lazy(() =>
  import("./pages/AuthVerifySignupSetPassword")
)

const Home = lazy(() => import("./pages/Home"))

const Board = lazy(() => import("./pages/Board"))
const BoardAnnounces = lazy(() => import("./pages/BoardAnnounces"))
const BoardAnnounce = lazy(() => import("./pages/BoardAnnounce"))

const Me = lazy(() => import("./pages/Me"))
const MeAnnounces = lazy(() => import("./pages/MeAnnounces"))
const MeInterests = lazy(() => import("./pages/MeInterests"))
const MeAccount = lazy(() => import("./pages/MeAccount"))

const Admin = lazy(() => import("./pages/Admin"))
const AdminAnnounces = lazy(() => import("./pages/AdminAnnounces"))
const AdminManifests = lazy(() => import("./pages/AdminManifests"))
const AdminAnnounce = lazy(() => import("./pages/AdminAnnounce"))
const AdminRoles = lazy(() => import("./pages/AdminRoles"))
const AdminInterests = lazy(() => import("./pages/AdminInterests"))
const AdminRegions = lazy(() => import("./pages/AdminRegions"))
const AdminDepartments = lazy(() => import("./pages/AdminDepartments"))
const AdminDistricts = lazy(() => import("./pages/AdminDistricts"))
const AdminCategories = lazy(() => import("./pages/AdminCategories"))
const AdminTypes = lazy(() => import("./pages/AdminTypes"))
const AdminSocieties = lazy(() => import("./pages/AdminSocieties"))
const AdminCodes = lazy(() => import("./pages/AdminCodes"))
const AdminAgents = lazy(() => import("./pages/AdminAgents"))
const AdminReports = lazy(() => import("./pages/AdminReports"))
const AdminReport = lazy(() => import("./pages/AdminReport"))

function RootRoute() {
  const bourseId = document.getElementsByTagName("body")?.[0]?.id
  return (
    <Route
      path="/"
      Component={Home}
      fetchPolicy="store-and-network"
      query={graphql`
        query routes_Home_Query($bourseId: ID!) {
          me {
            id
          }
          bourse: node(id: $bourseId) {
            ...Home_bourse
          }
        }
      `}
      prepareVariables={(params: any) => ({
        ...params,
        bourseId,
      })}
    >
      <Route
        Component={Board}
        fetchPolicy="store-and-network"
        query={graphql`
          query routes_Board_Query {
            data: viewer {
              ...Board_data
            }
          }
        `}
      >
        <Route
          path="announces/:id"
          Component={BoardAnnounce}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_BoardAnnounce_Query($id: ID!, $isAdmin: Boolean!) {
              viewer {
                ...BoardAnnounce_viewer @arguments(id: $id, isAdmin: $isAdmin)
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => ({
            ...params,
            isAdmin: false,
          })}
        />

        <Route
          Component={BoardAnnounces}
          fetchPolicy="store-and-network"
          exact={true}
          query={graphql`
            query routes_BoardAnnounces_Query(
              $first: Int
              $after: String
              $bourseId: ID!
              $regionId: ID
              $departmentId: ID
              $districtId: ID
              $categoryId: ID
              $typeId: ID
              $state: String
              $search: String
              $orderBy: AnnounceOrdering
            ) {
              user: viewer {
                ...BoardAnnounces_user
              }
              data: viewer {
                ...BoardAnnounces_data
                  @arguments(
                    first: $first
                    after: $after
                    bourseId: $bourseId
                    regionId: $regionId
                    departmentId: $departmentId
                    districtId: $districtId
                    categoryId: $categoryId
                    typeId: $typeId
                    state: $state
                    search: $search
                    orderBy: $orderBy
                  )
              }
            }
          `}
          prepareVariables={(params: any, { location }: any) => {
            return {
              ...params,
              ...extractKeysFromLocation(location),
              bourseId,
              first: 2,
              state: "validated",
              orderBy: { lastActivationDate: "DESC" },
            }
          }}
        />
        <Route
          path="me"
          Component={Me}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_Me_Query {
              user: viewer {
                ...Me_user
              }
            }
          `}
        >
          <Route
            path="announces"
            Component={MeAnnounces}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_MeAnnounces_Query(
                $first: Int
                $after: String
                $bourseId: ID!
                $regionId: ID
                $departmentId: ID
                $districtId: ID
                $categoryId: ID
                $typeId: ID
                $userId: ID
                $state: String
                $search: String
                $orderBy: AnnounceOrdering
              ) {
                user: viewer {
                  ...MeAnnounces_user
                }
                data: viewer {
                  ...MeAnnounces_data
                    @arguments(
                      first: $first
                      after: $after
                      bourseId: $bourseId
                      regionId: $regionId
                      departmentId: $departmentId
                      districtId: $districtId
                      categoryId: $categoryId
                      typeId: $typeId
                      userId: $userId
                      state: $state
                      search: $search
                      orderBy: $orderBy
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 10,
              userId: "me",
              bourseId,
              orderBy: { createdAt: "DESC" },
            })}
          />

          <Route
            path="interests"
            Component={MeInterests}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_MeInterests_Query(
                $first: Int
                $after: String
                $bourseId: ID!
                $regionId: ID
                $departmentId: ID
                $districtId: ID
                $categoryId: ID
                $typeId: ID
                $userId: ID
                $search: String
              ) {
                data: viewer {
                  ...MeInterests_data
                    @arguments(
                      first: $first
                      after: $after
                      bourseId: $bourseId
                      regionId: $regionId
                      departmentId: $departmentId
                      districtId: $districtId
                      categoryId: $categoryId
                      typeId: $typeId
                      userId: $userId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 10,
              userId: "me",
              bourseId,
              orderBy: { createdAt: "DESC" },
            })}
          />

          <Route
            path="account"
            Component={MeAccount}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_MeAccount_Query {
                viewer {
                  ...MeAccount_viewer
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
            })}
          />
        </Route>
        <Route
          path="admin"
          Component={Admin}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_Admin_Query {
              viewer {
                ...Admin_viewer
              }
            }
          `}
        >
          <Route
            path="announces/:id"
            Component={AdminAnnounce}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminAnnounce_Query($id: ID!, $isAdmin: Boolean!) {
                viewer {
                  ...AdminAnnounce_viewer @arguments(id: $id, isAdmin: $isAdmin)
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              isAdmin: true,
            })}
          />

          <Route
            path="announces"
            Component={AdminAnnounces}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminAnnounces_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $regionId: ID
                $departmentId: ID
                $districtId: ID
                $categoryId: ID
                $typeId: ID
                $state: String
                $search: String
                $orderBy: AnnounceOrdering
              ) {
                user: viewer {
                  ...AdminAnnounces_user
                }
                data: viewer {
                  ...AdminAnnounces_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      regionId: $regionId
                      departmentId: $departmentId
                      districtId: $districtId
                      categoryId: $categoryId
                      typeId: $typeId
                      state: $state
                      search: $search
                      orderBy: $orderBy
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              bourseId,
              first: 12,
              orderBy: { createdAt: "DESC" },
            })}
            render={({ props }) => <AdminAnnounces {...(props as any)} />}
          />

          <Route
            path="manifests"
            Component={AdminManifests}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminManifests_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $announceId: ID
                $regionId: ID
                $departmentId: ID
                $districtId: ID
                $categoryId: ID
                $typeId: ID
                $transportSocietyId: ID
                $designatedFSocietyId: ID
                $search: String
                $orderBy: ManifestOrdering
              ) {
                data: viewer {
                  ...AdminManifests_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      announceId: $announceId
                      regionId: $regionId
                      departmentId: $departmentId
                      districtId: $districtId
                      categoryId: $categoryId
                      transportSocietyId: $transportSocietyId
                      designatedFSocietyId: $designatedFSocietyId
                      typeId: $typeId
                      search: $search
                      orderBy: $orderBy
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              bourseId,
              first: 12,
              orderBy: { createdAt: "DESC" },
            })}
            render={({ props }) => <AdminManifests {...(props as any)} />}
          />

          <Route
            path="interests"
            Component={AdminInterests}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminInterests_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $regionId: ID
                $departmentId: ID
                $districtId: ID
                $categoryId: ID
                $typeId: ID
                $search: String
              ) {
                data: viewer {
                  ...AdminInterests_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      regionId: $regionId
                      departmentId: $departmentId
                      districtId: $districtId
                      categoryId: $categoryId
                      typeId: $typeId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              bourseId,
            })}
            render={({ props }) => <AdminInterests {...(props as any)} />}
          />

          <Route
            path="roles"
            Component={AdminRoles}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminRoles_Query(
                $first: Int
                $after: String
                $bourseId: ID!
                $search: String
              ) {
                viewer {
                  ...AdminRoles_viewer
                    @arguments(
                      first: $first
                      after: $after
                      bourseId: $bourseId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              bourseId,
            })}
          />
          <Route
            path="users"
            Component={AdminAgents}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminAgents_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $search: String
                $orderBy: AgentOrdering
              ) {
                viewer {
                  ...AdminAgents_viewer
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      search: $search
                      orderBy: $orderBy
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              bourseId,
              first: 10,
              orderBy: { createdAt: "DESC" },
            })}
          />
          <Route path="reports" Component={AdminReports} />
          <Route
            path="reports/:reportContent-:dataContent-over-:groupByTimePeriod"
            Component={AdminReport}
          />
          <Route
            path="reports/:reportContent-:dataContent-by-:groupByNode"
            Component={AdminReport}
          />
          <Route
            path="settings/regions"
            Component={AdminRegions}
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminRegions_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $search: String
              ) {
                data: viewer {
                  ...AdminRegions_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 25,
              bourseId,
            })}
            render={({ props }) => <AdminRegions {...(props as any)} />}
          />
          <Route
            path="settings/departments"
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminDepartments_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $regionId: ID
                $search: String
              ) {
                data: viewer {
                  ...AdminDepartments_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      regionId: $regionId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 25,
              bourseId,
            })}
            render={({ props }) => <AdminDepartments {...(props as any)} />}
          />

          <Route
            path="settings/districts"
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminDistricts_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $regionId: ID
                $departmentId: ID
                $search: String
              ) {
                data: viewer {
                  ...AdminDistricts_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      regionId: $regionId
                      departmentId: $departmentId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 25,
              bourseId,
            })}
            render={({ props }) => <AdminDistricts {...(props as any)} />}
          />

          <Route
            path="settings/categories"
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminCategories_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $search: String
              ) {
                data: viewer {
                  ...AdminCategories_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 25,
              bourseId,
            })}
            render={({ props }) => <AdminCategories {...(props as any)} />}
          />

          <Route
            path="settings/types"
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminTypes_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $categoryId: ID
                $search: String
              ) {
                data: viewer {
                  ...AdminTypes_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      categoryId: $categoryId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 25,
              bourseId,
            })}
            render={({ props }) => <AdminTypes {...(props as any)} />}
          />

          <Route
            path="settings/codes"
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminCodes_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $search: String
              ) {
                data: viewer {
                  ...AdminCodes_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 25,
              bourseId,
            })}
            render={({ props }) => <AdminCodes {...(props as any)} />}
          />

          <Route
            path="settings/societies"
            fetchPolicy="store-and-network"
            query={graphql`
              query routes_AdminSocieties_Query(
                $first: Int
                $after: String
                $status: String
                $bourseId: ID!
                $regionId: ID
                $departmentId: ID
                $districtId: ID
                $search: String
              ) {
                data: viewer {
                  ...AdminSocieties_data
                    @arguments(
                      first: $first
                      after: $after
                      status: $status
                      bourseId: $bourseId
                      regionId: $regionId
                      departmentId: $departmentId
                      districtId: $districtId
                      search: $search
                    )
                }
              }
            `}
            prepareVariables={(params: any, { location }: any) => ({
              ...params,
              ...extractKeysFromLocation(location),
              first: 10,
              bourseId,
            })}
            render={({ props }) => <AdminSocieties {...(props as any)} />}
          />
        </Route>
      </Route>
      <Route path="auth" Component={Auth}>
        <Route
          path="signup"
          Component={AuthSignup}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_AuthSignup_Query {
              data: viewer {
                ...AuthSignup_data
              }
            }
          `}
        />
        <Route
          path="signin"
          Component={AuthSignin}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_AuthSignin_Query {
              data: viewer {
                ...AuthSignin_data
              }
            }
          `}
        />
        <Route
          path="signout"
          Component={AuthSignout}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_AuthSignout_Query {
              data: viewer {
                ...AuthSignout_data
              }
            }
          `}
        />
        <Route
          path="forgot-password"
          Component={AuthSendResetPwd}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_AuthSendResetPwd_Query {
              data: viewer {
                ...AuthSendResetPwd_data
              }
            }
          `}
        />
        <Route
          path="reset-password"
          Component={AuthResetPwd}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_AuthResetPwd_Query {
              data: viewer {
                ...AuthResetPwd_data
              }
            }
          `}
        />
        <Route
          path="verify-password"
          Component={AuthVerifySignupSetPassword}
          fetchPolicy="store-and-network"
          query={graphql`
            query routes_AuthVerifySignupSetPassword_Query {
              data: viewer {
                ...AuthVerifySignupSetPassword_data
              }
            }
          `}
        />
      </Route>
    </Route>
  )
}

export default makeRouteConfig(RootRoute())
