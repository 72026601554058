import {
  BackendModule,
  Services,
  ReadCallback,
  TOptions,
  InitOptions,
} from "i18next"

//imported by default
import frLocales from "./fr"

type ResourceLng_Type = Record<string, Record<string, string>>
type ResourceLngCache_Type = Record<string, ResourceLng_Type>

export default class LocalBackend implements BackendModule<{}> {
  static type = "backend"
  type: "backend" = "backend"
  resourceLngCache: ResourceLngCache_Type = {}

  //constructor(services, backendOptions, i18nextOptions) {}

  async getLanguageResource(lng: string) {
    if (this.resourceLngCache[lng]) return this.resourceLngCache[lng]
    const resource = await new Promise<ResourceLng_Type>((resolve) => {
      if (lng === "en") {
        import("./en").then((en) => resolve(en.default))
      } else {
        resolve(frLocales)
      }
    })
    this.resourceLngCache[lng] = resource
    return this.resourceLngCache[lng]
  }

  init(
    services: Services,
    backendOptions: TOptions,
    i18nextOptions: InitOptions
  ) {
    console.log({ services, backendOptions, i18nextOptions })
  }

  read(lng: string, nsp: string, callback: ReadCallback) {
    nsp = nsp || "Global"
    this.getLanguageResource(lng).then((resource) =>
      callback(null, resource[nsp])
    )
  }

  create() {}
}
