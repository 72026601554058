/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_AdminRoles_QueryVariables = {
    first?: number | null;
    after?: string | null;
    bourseId: string;
    search?: string | null;
};
export type routes_AdminRoles_QueryResponse = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"AdminRoles_viewer">;
    } | null;
};
export type routes_AdminRoles_Query = {
    readonly response: routes_AdminRoles_QueryResponse;
    readonly variables: routes_AdminRoles_QueryVariables;
};



/*
query routes_AdminRoles_Query(
  $first: Int
  $after: String
  $bourseId: ID!
  $search: String
) {
  viewer {
    ...AdminRoles_viewer_2H0fKU
  }
}

fragment AdminRoles_viewer_2H0fKU on Viewer {
  bourse(id: $bourseId) {
    name
    ...RoleAdminList_bourse_1Ozsmw
    id
  }
}

fragment RoleAdminItem_role on Role {
  id
  title
  scope
  permissions
}

fragment RoleAdminList_bourse_1Ozsmw on Bourse {
  roles(first: $first, after: $after, search: $search) {
    totalCount
    edgeCount
    edges {
      node {
        id
        ...RoleAdminItem_role
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bourseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v5 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v6 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v7 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminRoles_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "bourseId",
                "variableName": "bourseId"
              },
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AdminRoles_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_AdminRoles_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "bourseId"
              }
            ],
            "concreteType": "Bourse",
            "kind": "LinkedField",
            "name": "bourse",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "edgeCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Role",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "scope",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "permissions",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v7/*: any*/),
                "filters": [
                  "search"
                ],
                "handle": "connection",
                "key": "RoleAdminList_roles",
                "kind": "LinkedHandle",
                "name": "roles"
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "baa973d4bcc74ab58d0a43377aac1954",
    "id": null,
    "metadata": {},
    "name": "routes_AdminRoles_Query",
    "operationKind": "query",
    "text": "query routes_AdminRoles_Query(\n  $first: Int\n  $after: String\n  $bourseId: ID!\n  $search: String\n) {\n  viewer {\n    ...AdminRoles_viewer_2H0fKU\n  }\n}\n\nfragment AdminRoles_viewer_2H0fKU on Viewer {\n  bourse(id: $bourseId) {\n    name\n    ...RoleAdminList_bourse_1Ozsmw\n    id\n  }\n}\n\nfragment RoleAdminItem_role on Role {\n  id\n  title\n  scope\n  permissions\n}\n\nfragment RoleAdminList_bourse_1Ozsmw on Bourse {\n  roles(first: $first, after: $after, search: $search) {\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        ...RoleAdminItem_role\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2189f965b7bc6c1467be9166d9822072';
export default node;
