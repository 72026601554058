import React from "react"

import { Environment } from "types.d"

export type AppContext_Type = {
  bourseId: string
  environment: Environment | any
  refreshEnv: () => any
}
export const BourseContext = React.createContext({
  bourseId: "",
  refreshEnv: () => null,
} as AppContext_Type)

export const useAppContext = () => {
  return React.useContext(BourseContext)
}

export default useAppContext
