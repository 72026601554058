export const COOKIE =
  process.env.JWT_TOKEN_KEY || "firekola.minepdedtis.webclient"

export const getCookie = (key: string) => {
  const allCookies = document.cookie.split("; ")
  let cookie
  for (let i = 0; i < allCookies.length; ++i) {
    cookie = allCookies[i].split("=")
    // eslint-disable-next-line eqeqeq
    if (key == cookie[0]) return cookie[1]
  }
  return null
}

export const setCookie = (key: string, value: string) =>
  (document.cookie = `${key}=${value}; path=/`)

export const removeCookie = (key: string) =>
  (document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`)
