/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_AdminRegions_QueryVariables = {
    first?: number | null;
    after?: string | null;
    status?: string | null;
    bourseId: string;
    search?: string | null;
};
export type routes_AdminRegions_QueryResponse = {
    readonly data: {
        readonly " $fragmentRefs": FragmentRefs<"AdminRegions_data">;
    } | null;
};
export type routes_AdminRegions_Query = {
    readonly response: routes_AdminRegions_QueryResponse;
    readonly variables: routes_AdminRegions_QueryVariables;
};



/*
query routes_AdminRegions_Query(
  $first: Int
  $after: String
  $status: String
  $bourseId: ID!
  $search: String
) {
  data: viewer {
    ...AdminRegions_data_3TqN5O
  }
}

fragment AdminRegions_data_3TqN5O on Viewer {
  bourse(id: $bourseId) {
    name
    ...RegionList_bourse_1zqZ43
    id
  }
}

fragment RegionItem_region on Region {
  id
  name
  code
  capital
}

fragment RegionList_bourse_1zqZ43 on Bourse {
  regions(first: $first, after: $after, status: $status, search: $search) {
    totalCount
    edgeCount
    edges {
      node {
        id
        ...RegionItem_region
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bourseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v5 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v6 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v7 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v8 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = [
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminRegions_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "bourseId",
                "variableName": "bourseId"
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AdminRegions_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_AdminRegions_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "bourseId"
              }
            ],
            "concreteType": "Bourse",
            "kind": "LinkedField",
            "name": "bourse",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "RegionConnection",
                "kind": "LinkedField",
                "name": "regions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "edgeCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RegionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Region",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "capital",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "status",
                  "search"
                ],
                "handle": "connection",
                "key": "RegionList_regions",
                "kind": "LinkedHandle",
                "name": "regions"
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0bdd9d0b14bc7a87bbf7fe9ba10647c9",
    "id": null,
    "metadata": {},
    "name": "routes_AdminRegions_Query",
    "operationKind": "query",
    "text": "query routes_AdminRegions_Query(\n  $first: Int\n  $after: String\n  $status: String\n  $bourseId: ID!\n  $search: String\n) {\n  data: viewer {\n    ...AdminRegions_data_3TqN5O\n  }\n}\n\nfragment AdminRegions_data_3TqN5O on Viewer {\n  bourse(id: $bourseId) {\n    name\n    ...RegionList_bourse_1zqZ43\n    id\n  }\n}\n\nfragment RegionItem_region on Region {\n  id\n  name\n  code\n  capital\n}\n\nfragment RegionList_bourse_1zqZ43 on Bourse {\n  regions(first: $first, after: $after, status: $status, search: $search) {\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        ...RegionItem_region\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '41480ab84cb8e9f69611bcb75c7c45e3';
export default node;
