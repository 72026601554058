/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type routes_AdminDepartments_QueryVariables = {
    first?: number | null;
    after?: string | null;
    status?: string | null;
    bourseId: string;
    regionId?: string | null;
    search?: string | null;
};
export type routes_AdminDepartments_QueryResponse = {
    readonly data: {
        readonly " $fragmentRefs": FragmentRefs<"AdminDepartments_data">;
    } | null;
};
export type routes_AdminDepartments_Query = {
    readonly response: routes_AdminDepartments_QueryResponse;
    readonly variables: routes_AdminDepartments_QueryVariables;
};



/*
query routes_AdminDepartments_Query(
  $first: Int
  $after: String
  $status: String
  $bourseId: ID!
  $regionId: ID
  $search: String
) {
  data: viewer {
    ...AdminDepartments_data_2SSyDU
  }
}

fragment AdminDepartments_data_2SSyDU on Viewer {
  bourse(id: $bourseId) {
    name
    ...DepartmentList_bourse_1hTbLi
    id
  }
}

fragment DepartmentItem_department on Department {
  id
  name
  capital
  region {
    id
    name
  }
}

fragment DepartmentList_bourse_1hTbLi on Bourse {
  departments(first: $first, after: $after, status: $status, regionId: $regionId, search: $search) {
    totalCount
    edgeCount
    edges {
      node {
        id
        ...DepartmentItem_department
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bourseId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v6 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v7 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v8 = {
  "kind": "Variable",
  "name": "regionId",
  "variableName": "regionId"
},
v9 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v10 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "routes_AdminDepartments_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v6/*: any*/),
              {
                "kind": "Variable",
                "name": "bourseId",
                "variableName": "bourseId"
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "AdminDepartments_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "routes_AdminDepartments_Query",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "bourseId"
              }
            ],
            "concreteType": "Bourse",
            "kind": "LinkedField",
            "name": "bourse",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              {
                "alias": null,
                "args": (v12/*: any*/),
                "concreteType": "DepartmentConnection",
                "kind": "LinkedField",
                "name": "departments",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "edgeCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DepartmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Department",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "capital",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Region",
                            "kind": "LinkedField",
                            "name": "region",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v12/*: any*/),
                "filters": [
                  "status",
                  "regionId",
                  "search"
                ],
                "handle": "connection",
                "key": "DepartmentList_departments",
                "kind": "LinkedHandle",
                "name": "departments"
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2865bca9c1c127b69cd0c7d3409113f6",
    "id": null,
    "metadata": {},
    "name": "routes_AdminDepartments_Query",
    "operationKind": "query",
    "text": "query routes_AdminDepartments_Query(\n  $first: Int\n  $after: String\n  $status: String\n  $bourseId: ID!\n  $regionId: ID\n  $search: String\n) {\n  data: viewer {\n    ...AdminDepartments_data_2SSyDU\n  }\n}\n\nfragment AdminDepartments_data_2SSyDU on Viewer {\n  bourse(id: $bourseId) {\n    name\n    ...DepartmentList_bourse_1hTbLi\n    id\n  }\n}\n\nfragment DepartmentItem_department on Department {\n  id\n  name\n  capital\n  region {\n    id\n    name\n  }\n}\n\nfragment DepartmentList_bourse_1hTbLi on Bourse {\n  departments(first: $first, after: $after, status: $status, regionId: $regionId, search: $search) {\n    totalCount\n    edgeCount\n    edges {\n      node {\n        id\n        ...DepartmentItem_department\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c310b7319d43c3a6e3a6e2853ff9c579';
export default node;
